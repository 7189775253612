import React from 'react';

import '../../css/Blog.scss';
import Header from '../A-WebElements/Header';
import Footer from '../A-WebElements/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const RandPaul = () => {
	return (
		<div className="conversations">
			<Header name="blog" />
			<Container fluid className="blog-content">
				<div className="header-politico">
					<h2>
						Co-Founder of Axios and Polticio Jim VandeHei at the Berkeley Forum
					</h2>
				</div>

				<Row>
					<p className="italics">
						Jim VandeHei appeared at the Berkeley Forum on October 19, 2017. Mr.
						VandeHei’s address centered around how the content revolution has
						emerged as a response to clickbait headlines. Mr. VandeHei spoke
						about his own journey in creating two major media publications, as
						well as how increased efficiency and quality of media produces value
						for its consumers. The event was moderated by Michael Chien.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> Mr. VandeHei, thanks so much for
						coming to speak to us.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: You’ve got to stop calling me Mr.
						VandeHei!
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> I wanted to get back to, I guess,
						the beginning, when you realized that we were falling into what you
						call the “Crap Trap”, the fake journalism. Why did you decide to
						break out from the norm [and] start Axios, instead of staying in
						POLITICO and maybe changing the rules of the game from within?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: Everything in life, there’s lots of
						reasons. One, I’m restless. I like to do new things. I would say I
						got addicted to starting companies or starting products when I was
						at POLITICO. I think I’m decent at it, so I wanted to give it
						another whirl. The big one, though, is I saw an opportunity. An
						opportunity, and with the way I look at businesses, now that we
						start them, is you’ve got to have, what is a need that needs to be
						filled? If you can fill that need, is there a plausible way to
						monetization of that idea? To me, the challenge now is different
						than when we started POLITICO. POLITICO, at the time, all it was
						filling to me was this need for more political coverage, more depth,
						with more analysis, hooked up to the web, taking advantage of cable
						TV. Our belief was that, that was what Washington needed, and our
						theory was that if Washington needed, anybody who cared about
						politics would ultimately get sucked into it. Mission accomplished.
						The new challenge, I’d say, for all of us is the one I outlined up
						there, which is the world is more complex today than it was a decade
						ago, and it’s going to get exponentially more complex, and that for
						people who care about serious news consumption, and I do believe
						that’s still a nice chunk of the country and a really nice chunk of
						the world, there has to be a better, more efficient way for people
						to have a fuller understanding of politics, technology, business,
						media consumption which is not Katie Couric’s contract, it’s a
						dissemination in consumption of information, but science, future of
						work, and so if you look at, if you go to Axios, those are our
						sections. Every one of the sections that we have are issues that are
						going to shape the future, all narrated by people who have expertise
						and following. We have what we call a radiate theory, that if we’re
						pretty good at getting Jamie Dimon as a reader, the president as a
						reader, or Sheryl Sandberg as a reader, then our theory is we can
						write stuff that they find essential, that ultimately that will echo
						out to anybody else who cares about it. The early results are very
						encouraging. People respond very well to it, and the neatest thing
						is, we do reader surveys. We sent one out maybe three months in. We
						sent it out, and we didn’t have that big a readership at the time. I
						think 12,000 people filled it out. It took 15 minutes, 6,000
						volunteered to answer any question we ever have, and it was so
						interesting to read it, because people were responding to it using
						language that we would use inside of our meetings. Say, “Oh, you’re
						helping me get smarter faster.” Oh, you’re making the reading of
						complex issues more efficient, more understandable, more
						approachable. This is easy to read, easy to share. It’s clean. Those
						things are super gratifying. It’s a long way of answering that there
						was an opportunity that we saw to fill. I believe that we’re filling
						it, and now I actually think this could be exponentially bigger than
						we even thought when we launched it nine months ago. That’s to me
						one of the most astonishing things. We just launched nine months
						ago, and I knew we were on to something when two months in the New
						York Times was quoting Axios without saying what it is. I was like,
						“Come on.” Two months in, as a new publication, and at least for
						people in the media, people in politics, people in technology, that
						they were that familiar with it, that they assumed their readership
						was familiar with it. I know that a lot of people aren’t familiar
						with it, so it probably should’ve explained what it was, and then
						said Axios.com.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> When you talk about, one of the
						resounding motifs of your address was this trust in specific that
						the media and journalism is now operating in. Especially given that
						Axios is a new company, how does it operate within the rules of that
						game? How does it make itself trustworthy despite being so new and
						so fresh?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: We have a formula. One is, we hire
						people who are already respected in their area, so Mike Allen in
						politics, Dan Primack who is probably the most respected writer on
						venture capital. Kim Hart, who’s probably the most respected writer
						on the collision of technology and Washington. Sara Fischer, who
						covers media trends. You hire people who already command respect and
						command a certain following. Then, you create a culture that demands
						that we’re there to write information. We don’t have an opinion
						page. You’re not allowed to pop off on Twitter with your opinion or
						on any social media, whether you’re on the business side or the
						editorial side, because we all have a responsibility. Everybody at
						our company is an owner. Every person has stock and will forever in
						the company, so that they feel a sense of ownership, because Axios
						which is Greek means worthy. Worthy of your trust, worthy of your
						time. They have a responsibility. If you treat people like adults in
						a workplace, they’ll respond like adults. If you put weird
						restrictions on them, they’ll act like toddlers and throw their
						little toys.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> Is that a mission that appeals to, I
						guess, the almost half of Americans who don’t really see the media
						as producing trustworthy content or even see it as a viable option
						of their own information edification? Is that the target population,
						or is it people that already think journalism is worthy of their
						time?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: To me, I look at the map in terms of
						people who should be consumers of Axios content over time as anybody
						who’s consuming serious news on a daily basis or should be. Listen,
						there’s a big segment of the country that’s never going to. I can’t
						make people care. I just can’t, but I can make the experience for
						people who want to care or who do care way better. It is, some of
						the people who thought fake news is fake news. Yeah, sure. We
						definitely hear from, we’ve got a nice ideological breakdown in our
						readership. I think people do trust us, because we are very
						clinical. We’re trying to explain what’s happening and why it’s
						happening. That’s it. Every story, every item, if you go on the
						Axios screen, what is new, what is interesting, and why does it
						matter? Why does it matter? How should I think about this, based on
						somebody who spent their lifetime thinking about this topic, and
						being in conversations with the people who spend the rest of their
						life thinking about this topic? Those are the only things I know
						that you can do to earn trust, and then when you’re on TV, doing the
						interview like I am here. Not betraying an ideological bias, and
						having an authenticity about yourself as a publication, and I think
						collectively if you had any of the other people at Axios here, they
						would hopefully sound a lot like me. You would say they’re
						authentic, they care, they’re in it for the right reasons. They’re
						trying their best, and knowing we’re not always going to get it
						right. We’re going to screw stuff up. We just are, but then don’t
						hide and bury your correction, and do all the stupid tricks that
						people in media have done. Just say, “We screwed up. This is how we
						screwed it up. Forgive us.” Move on.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> I guess what I’m trying to get at is
						that a lot of what you deem to be the problem in journalism, people
						clicking on tricks-for-clicks as I think you have termed it, if
						those people who aren’t going to legitimate or trustworthy
						publications, have proliferated this problem, why is it that the
						solution should ignore these people, that don’t really get their
						news from trustworthy sources, that would never look toward Axios?
						Is it just to neglect them, and to let them make their own, use
						their own facts, or is there some sort of strategy for Axios, as a
						new disruptive company to really reach those users regardless of
						what their views are?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: Again, you’re not ignoring someone.
						It would be the person, the consumer would be the one ignoring it.
						The way you make yourself omnipresent is you want to make sure
						you’re on every, that you’re basically delivering your content in
						every ecosystem in a way that fits with the voice of that ecosystem,
						meaning the way you display yourself on on app has to be different
						than Facebook, has to be different than Snapchat, has to be
						different than Instagram, has to be different than Newsletter, it
						has to be different than a web platform. Then, different than if
						you’re on TV. When that’s out there, that’s out there. They can
						choose. I can’t make you choose. You might be really conservative,
						and you might only like fake news, and you might only read
						Breitbart, and you might only watch Sean Hannity. There’s nothing I
						can do to change her. I can’t say, “No! Sean’s an idiot! No!” –
						listen to me. She’s already, but I can put that out there, and not
						tip the scales, and not do anything for that person to distrust the
						content, and then just maybe, on that second beer as she’s watching
						Sean late at night, she suddenly has a revelation. She says, “You
						know what? I don’t trust this shit. I’m going to read that.”
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> You put out better content, people
						choose.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: How else can you do it? You tell me.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> That’s not my field of expertise,
						but I guess what I’m trying to say is that…
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> Maybe there is an angle that
						encapsulates all of civil discourse, and all of the people who will
						cast ballots for public office. Is there an endgame for journalism
						and for Axios that encapsulates all of the masses?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: I don’t know how to answer it any
						other way than this. I’m brutally clinical about life. I am brutally
						clinical. I can look at the data, I can look at people, I can look
						at human nature, I can look at tendencies, I can look at trends, I
						can look at consumption, and I can tell you that there’s, not just
						today. There always was a big segment of people who never cared
						about news. They don’t have to care about news, it’s the beauty of
						our system. For all the bitching about America, it’s an amazing
						country, where you can thrive without ever picking up a newspaper,
						without ever going to Axios, you get to do your job, be a good
						person, have a family. You’re going to be fine.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> Those who will go to Axios, though,
						what do you see as the main value proposition? Is it the brevity,
						the efficiency? Is it the quality of content? If it’s one single
						thing, what sets it apart from the rest of the race?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: It’s definitely two things. It’s the
						smarts and it’s the efficiency. We call it, you’re actually
						trademarking it, smart brevity, in that it’s really smart. It’s
						really easy to consume, and it’s really efficient. If you want to
						read more, you don’t have to go to another page. It just drops down
						beautifully in your screen. You’re not going to have a pop-up ad. It
						is, I think, the nicest possible user architecture created for
						media. It’s awesome. I had nothing to do with it. This woman, Alexis
						Lloyd, who is at the New York Times. She’s working at the New York
						Times lab, and she was studying the future of news, looking at data.
						She came to the exact same conclusion that we did, coming in from a
						totally different angle. She said, “Oh, my gosh, this is exactly
						what people want.” By the time we met her, she could finish our
						sentences, and then, she could do something I couldn’t do, which is
						design something. Then Matt Baume, who was also at the New York
						Times lab building that stuff, came on because he wanted to build
						it, exactly the way that we were articulating it, and the way that
						Alexis was designing it. That’s where, if you get the opportunity to
						be part of a startup, there is nothing more fun in the professional
						side of your life. It is a blast, to have an idea, and to see an
						idea take off with a group of people who come at it with really
						different talent sets, really different backgrounds, that clash of
						ideas, the clash of personalities, the clash of egos, and then
						voila. An idea comes from that, that works, that people read or
						people trust, they’re just the coolest possible thing. Not everyone
						will be able to do it, but you should all look at yourselves as
						entrepreneurs, even if you’re going to go through a more
						conventional route through work, in that it’s not going to be my
						parents’ generation, where you’re somewhere for 40 years. You’re
						going to constantly be moving. You have to constantly think about
						the skill sets that you need. You have to constantly be aware.
						Listen, the world that you’re going to live in is going to be much
						harder than the world I grew up in, in that change is only going to
						get quicker. Everything is going to change. Robotics, artificial
						intelligence, automation’s going to change each and every job, each
						and every industry, and it’s going to happen quicker than most of us
						are capable of keeping up with. It’s going to require all of you to
						be a lot more entrepreneurial and not be that complacent in your
						professional life. You’re in the right part of the gene pool, right?
						You’re at this university. You’re really smart as hell. Shame on
						you, if you’re not successful coming out of here, right?
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: You’ve got it going on.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> I want to delve a little bit, I know
						that my time is running out, but into the product name process,
						talking about with that seamless user experience. I’ve been to the
						site. It is very beautiful, as you say. Part of that decision, I
						think, may have been to include, I guess, native advertising within
						that platform. Do you think that complicates the quality of the
						content in any way, in terms of presenting information to the user?
						What was the decision-making rationale behind that?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: One, I’m a capitalist. I believe
						that…I’m a big believer. For-profit companies, and they create a
						type of culture and a type of company that’s going to be a lot more
						dynamic. I love that people put money into not-for-profits. I don’t
						think that’s the best solution for journalism. I think for-profit
						companies are the best. There’s only a couple ways to make money in
						journalism. You can sell subscriptions, you can sell ads, you can do
						events. They’re basically the three ways of making money in
						journalism. For me, as long as you’re marking something as sponsored
						content or as an ad, I’m all for it. I’m very pro-it. What we did
						is, we said, “Okay, let’s sit down.” If you’re going to design an ad
						that provides the least amount of intrusion and distraction for the
						reader but forces the reader to interact with it in a way that the
						advertiser gets value. Too many media companies, “Oh, advertisers.”
						It’s your money. If you don’t have advertising, you don’t have
						money. You don’t have money, you don’t have a job. You don’t have,
						then there’s no company. You want to make that experience beautiful
						for the reader, beautiful for the advertiser. All we did is, we
						looked at, what is the best experience out there for the advertiser,
						for the reader? It’s with Facebook, setting up the nice stream,
						where you go through because it’s there. You can choose whether or
						not to stop. You’re working with a small space, so if you’re clever
						about your graphics, your video, your headlines, you can actually
						get awesome interaction with it. Our ads have a 2-4x engagement
						improvement from any other ad for that very reason, because we put a
						lot of time, thinking, into the making those as beautiful as the
						content themselves. As long as they’re labeled, that’s all you can
						do. That’s all you should do, and you need them. If you take them
						away, you’ve got nothing, because one of the dumbest things the
						media industry did, maybe one of the dumbest things any industry has
						done in the history of all industries, was that our industry, when
						the Internet came along, we said, “Hey, you know what? Let’s all
						band together. Let’s take a product that’s extremely expensive to
						produce, really valuable,” something the consumer really likes a
						lot, “You know what we should do? Let’s give it away for free.”
						Wouldn’t that be an awesome idea? That’s what we did for ten years.
						We habituated an entire generation of consumers to take something
						for free that costs a lot of money. You don’t get your music for
						free. You don’t get your movies for free. You don’t get your food
						for free. We were giving away a product for free. That needs to be
						corrected, and one way is through subscriptions. You’ve been
						habituated to not pay for content. Other than the New York Times,
						the Washington Post, Wall Street Journal, FT. I would say the Wall
						Street Journal and the FT are business expenses, so I put that on a
						different category. The New York Times and only recently the
						Washington Post, there’s very few examples of content companies that
						people are willing to pay enough money for to support a viable media
						company. That is what it is. I’m talking about the written news
						space. Maybe that changes with time. I don’t know. We’ll see if it
						does. That’s an open question. That’s a long way of saying, I love
						our ads.
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Michael Chien:</strong> Unfortunately for me, but good for
						the rest of you, this is my last question. Among all the things
						we’ve talked about, from the Internet, to advertising, to quality
						content, fake news, a lot of people would say that money is the
						biggest factor when it comes to journalism management. You’ve said
						in the past that, at POLITICO, you guys reached what you called a
						nirvana, where 50% of your revenue came from ads, the other 50% was
						subscriptions. Why is it not 100 to 0? Is that because it’s not
						feasible, or is it because the ads genuinely provide, I guess, value
						for the stakeholders and for the readers, in consuming that content?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: The reason is that, number one, at
						POLITICO, the subscription side is not like the New York Times. At
						POLITICO we created what we call POLITICO Pro, which is a high-end
						subscription service for people who are doing politics and policy
						professionally. The starting price is $8,000. It’s not like you’re
						buying that or we’re buying that. You would have to be doing it as a
						business. It turned out to be an awesome business, but if that’s all
						we did, all we would’ve been doing is writing content for a very
						small sliver of people that saw great value in it, but you’re not
						really doing the public service dimension of it. If I just wanted to
						build a business just to make money, that’s what I would do. It’s
						the easiest way to make money in journalism right now, high-end
						information, charge a lot of money, boom. I don’t like that. I like
						it as a business, but I don’t like it alone. I like it if it helps
						support public journalism. POLITICO, a couple weeks ago, it was one
						of the finest moments since I left POLITICO, to watch, we had
						created healthcare verticals, our first one cost you about ten grand
						for a company or association to get it. Our theory was, if we could
						get six or eight people covering healthcare, and you’re doing it
						with people who’ve been doing it at the most granular level, a
						couple times a year, they’re going to come across awesome journalism
						of political consequence, or there’s going to be a difficult,
						complex debate, but you’re going to have all this brainpower behind
						it, and you take that content, and you put it public. The group of
						people that exposed the HHS secretary and forced him out, because he
						was doing illegal travel, was from that POLITICO Pro team. The
						theory of the case worked, but you need, I think you need, both. The
						public dimension of journalism is why I’m a journalist. The fact
						that you can make a difference, you can inform tons of people, that
						you can help shape how people are seeing really important ideas.
						That part of it, at least based on the economics and the habits
						today, has to be supported by advertising. There’s not another way
						around it. I run into people that, especially out here a lot, who
						don’t like advertising. I’m begging you, like advertising.
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: You’ve got to, otherwise there’s no
						way to support the type of content that you’re reading. It’s not
						bad. Actually, I think the advertising is starting to get better,
						and so if you don’t have that, you’re not going to have the content
						that you’re getting for free. That’s just the laws of economics.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Given that the mission of Axios is
						to provide information very succinctly. First, you’ve advocated for
						an anti-establishment innovation party. Do we have a Zuckerberg or a
						Sandberg running in 2020? How do you see their path to the
						Presidency? Then, also, given that there’s a lot of
						anti-establishment values against our generation, what would you say
						to those young people like the millennials and slightly older who
						look at Axios and see some of the business partners, such as banks,
						pharma, industries that are skeptical, that Axios is the best forum
						to really give trustworthy anti-establishment information?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: All I can say is, the beauty of the
						content is there. You can read it. You’re either going to trust it
						or not. You might not like the Koch brothers. You might not like a
						bank. You might not like the Wildlife Federation, whoever it is
						that’s advertising on any given day. If that scares you off, I can’t
						change it. The advertisers advertise, and I am not discriminating
						against advertisers, as long as you’re not saying something that’s
						mean-spirited, like totally wrong, or violent, or whatever. We have
						our parameters on that. What I would say, look at the content. Look
						at the quality of the content. Is this something that you find
						edifying? Is it something that you trust? Is it something that’s
						making you smarter faster? If it’s not, don’t read it. If it is,
						read a lot more of it, and then tell your friends about it,
						Axios.com.
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: I wrote a piece for the Wall Street
						Journal during my period between leaving POLITICO, my cooling-off
						period before I started Axios. The point of the piece was that the
						system needs a shock. In some ways, you need something different
						than we have, and throughout different ideas, like Zuckerberg,
						whoever, but somebody who’s thinking about it differently, in that,
						and I actually believe this even more powerfully today than I did
						then. The system, put aside Trump. The system in some ways is so
						detached from the reality that we’re about to have. We have two
						political parties that spend their time arguing about things that
						made a lot of sense 15 years ago, made some sense 10 years ago,
						still make a little bit of sense, but they’re not actually the
						topics that are going to define whether or not China eats our lunch
						in the next 20 years. We’ve got, we want. You’ve got to be able to
						modernize government. Cybersecurity, I guarantee the catastrophe we
						have will flow from some cyber attack that none of us are thinking
						about right now. If you don’t have the right technologists and the
						right technology, redoing government, we’re hugely vulnerable. Then
						you take that overseas. We grew up in a time of conventional wars.
						We’re going to take boots on the ground. We’re going to go to war
						with another country. That’s never going to happen again. All of
						these things are going to be fought in space, and they’re going to
						be fought with drones. Any given day, this very day, this would have
						been true under Obama too, we’re executing people via drone in four
						different countries. Have been for the last six-seven years. Will be
						probably forever. Our enemies aren’t necessarily just going to be
						nation states. They’re going to be things like Al Qaeda
						metastasizing into ISIS, which will metastasize into something else
						which requires a government that basically fought wars the same way
						for a long time to rethink about the War Powers Act. How do you move
						fast enough to be able to adapt to these different threats? Then,
						you look domestically. What is the fundamental challenge? You can
						bemoan globalization all you want, and there’s pros and cons to it,
						but the technology is only going to bring people and countries
						closer together, markets closer together. That is the arc of
						history. I don’t believe you can bend it any other way. Then, you
						have to look at the country. You say, “What is it that we need to do
						to make sure that, this is the best damn country ten years from
						now?” One, you’d better make sure that this is the place that people
						want to come and start businesses. It still is. People love this
						country. They might be down on it because of Trump or different
						dynamics that we have, but people love America. They still think
						it’s the best place to start companies, the best place to birth new
						ideas. If you don’t get that right over time, if you don’t get, are
						we getting people who come here? Are we getting immigrants who want
						to come here, start companies? They come here, start companies, and
						stay here. Are you then creating a tax code that makes sense in a
						globalized system, where you have mass amounts of competitors that
						might have a competitive disadvantage or competitive advantage
						overseas? You need to be able to rewire the country on the domestic
						side and on the foreign side to make sure that we continue to crush
						it. We are, for all the hangdog crap I hear about, “Oh, my God!
						America’s going to Hell!” It’s not going to Hell. I’ve spent, we’ve
						built a company in Europe before we left POLITICO. I’d much, much,
						much rather be an American than be sitting in Europe. They’re in a
						lot of trouble. Their governing system doesn’t work. Their borders
						are really poor. They’re dealing with terrorists threats and the
						effects of that, and migration in ways that are much more powerful
						than some of the debates that we’re having here. “Oh, China, China,
						China!” Come on. Yes, they could steal our ideas, which they do.
						They can bully companies into doing things in their country. They
						can do that. They have lots of people, but it’s a crappy governing
						system that doesn’t have as dynamic of a culture, and is not, I
						don’t believe, going to be a long-term threat, as big a long-term
						threat as people fear, if we get those things right. Otherwise, if
						we don’t, it will, or India will, or Vietnam will, or whoever else
						will. That’s a long way of answering the journal piece about just
						saying, I do think that there probably needs to be a new set of
						ideas that people are talking about, and it actually might happen
						soon, because if somebody’s, look at what’s happening with the two
						parties. In some ways, we probably should have four political
						parties right now, because the difference between the Trump wing of
						the party and the Ryan wing of the party, they’re almost two
						different parties. They have really pulled themselves apart. The
						difference between the Clinton wing and the Sanders wing is pretty
						profound. I think really profound, and that Sanders wing is only
						getting pulled further to the left. In some ways, if you have four
						different parties right now on a lot of these topics, you might have
						the Sanders wing and the Trump wing often teaming up, especially
						when it comes to issues like trade, US jobs, and so I don’t know how
						this plays out. All I know is that every single job, every single
						sector, is getting disrupted and will continue to get disrupted. I
						don’t think politics is any different. Is technology or
						technologists, the Zuckerbergs of the world, the solution? I don’t
						know. I think that those companies have a bigger political priority.
						I don’t think Mark Zuckerberg hired David Plouffe and other people
						because he’s thinking he’s going to run for president. I think he’s
						smartly realized he has a hell of a political problem on his hands,
						that the more people learn about what’s happening on Facebook, the
						more they’re going to see their popularity fade, the more that
						they’re going to see regulators regulate. It is the single biggest
						story in this country this year, if you didn’t have Trump as
						president. It is basically, now, have this country starting to look
						at these big social platforms that we give all of our data, all of
						our time, all of our mindshare, and people are starting to think,
						“Whoa, wait a second. Did we give them too much?” Were they too
						unregulated? Is there too much mischief happening that we weren’t
						aware of, and should there be a correction? I think there’ll be a
						correction, and I think Europe in some ways is a template there,
						because Europe has a much different view of data privacy, data
						protection, and these big companies than we do. They regulate them
						more. They tax them more. They fine them more, and that creates a
						template for what could happen, here. I’m not guaranteeing it does
						happen here. We had, last week, we had Sheryl Sandberg. We’re the
						one company that interviewed her for a half hour. Mike Allen, on
						stage, grilled her pretty powerfully. They know they’re in for a
						grilling on Capitol Hill about what was happening on that platform,
						what responsibility they have to be aware of it sooner than they
						actually were, and what do they have to do now to make sure that,
						that doesn’t happen again? That’s an amazing story. I think the best
						book that’s going to be written is going to be the Mueller Report.
						Think about it. Everyone’s like, “Oh, is he going to indict Trump?”
						I don’t know. I have my own theories on that, but what he is going
						to do is, he’s not just looking at Trump. He’s looking at the entire
						system. He is going to pull together a report that looks at a
						foreign company, manipulating for the price of doughnuts, our
						election, on an unregulated platform, that may or may not have been
						in cahoots with either a campaign, or elements around a campaign, to
						undoubtedly influence at least in some ways the election. We’ll
						never know if it was decisive, but we’ll know that it was profound
						and potentially decisive, but we’ll never know decisive, that then
						has spawned all kinds of crazy activity, including the firing of
						Comey, which probably will go down in history as one of the dumbest
						things that a president’s ever done, because it then gave him Robert
						Mueller, who will write the report that I’m talking about.
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: It’s a fad. It’s scary times. There’s
						no doubt. I probably feel the same fears that you guys do. It’s also
						a fascinating time, because all of this stuff is coming together,
						and that’s what I was talking, that’s where we never would’ve
						predicted it when we launched Axios, but our theory was all this
						stuff will collide. We didn’t really know it would collide in this
						spectacular of a fireworks show, but it has.
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> I just had a question regarding
						your thoughts on for-profit news media. I’m actually from Canada,
						and in Canada our primary news source is Canadian Broadcasting
						Corporation. We use this and BBC, and at least from my personal
						experience is that people often trust the state-owned media sources,
						primarily because they have less of an incentive to, as you
						mentioned earlier, resort to the lowest common denominator, be more
						serious about their news, because they have a relatively stable
						source of funding. I just wanted to gauge your impact on the
						state-owned media resources and whether such a platform, whether
						something similar could work in the United States.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: We have PBS and NPR, which obviously
						get some amount of public funding. I don’t think that’s the answer.
						I don’t think, even if it were the answer, it’s never going to be
						the only thing, unless you had total state control, which then
						stomps out cataclysms. I would be opposed to that. Looking at it
						clinically, it’s an interesting question, just not going to happen,
						so I don’t spend a whole lot of time entertaining the possibility. I
						do agree with you, I don’t know, I’m familiar with the Canadian but
						again, I’m not going to lie to you. I don’t watch it.
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: The BBC I am pretty familiar with,
						and man, it’s a great place. There’s lots of great places for
						information. It can come even when I’m dogging on not-for-profits.
						I’m dogging on the culture that a not-for-profit can create, not the
						output. I love that we have lots of not-for-profits that are doing
						investigative reporting, which is the hardest type of reporting to
						actually create a business model around, but to me, you’re going to
						always end up with a mix of all those things, and so if it works in
						Canada, it’s great that it works in Canada. I will say, if you go to
						Britain, the SUF, the BBC, but I don’t know if that’s often the
						dominant voice. London is now a hotbed, for almost every single
						media company’s now creating a London outpost, and you have
						Buzzfeed, and Business Insider, and you have the tabloids there.
						London, other than New York, is probably one of the hottest places
						for that, with a really vibrant media market that gets you from a
						bunch of different angles. I think, again, I’m a big fan of more.
						I’m also a big fan of you understanding what it is you’re reading,
						and whether or not you can trust it or should trust it, and if
						something is fake or something is nonsense, that there’s a way for
						you to be able to easily pick up that, that’s fake or that’s
						nonsense. We’re not there yet.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> This is a product question. It
						involves your decision to start Axios, and I know obviously it’s not
						something like making podcasts, that’s a shift in media consumption
						that’s [changing] just the way that we’re consuming media. As
						progressives, do you think [there will continue to be a] go-to for
						liberal media? What do you see, the trends? How do you see media
						[changing over the next few years]?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jim VandeHei</strong>: It’s hard for me to tell what it
						looks like beyond the next couple of years. I think over the next
						couple of years, I think media consumption is going to become more
						and more awesome, in that as you get from 4G to 5G to 6G, as you get
						networks are quick enough, I think what you’re going to have is…
						Newsletters are interesting. Newsletters, in terms of really
						especially hyper-engaged readers, they’re awesome. Our newsletters
						do extraordinarily well, and people love it. Why? Because it’s
						efficient, it’s pushed to you, it’s shorter and smarter. It often
						has more voice. I think what we’ll see, and I think you’ll hopefully
						see it from us, is to me ideally newsletters, podcasts, all of that
						stuff blends itself into an app stream, meaning that you have a
						watch/listen/read experience that’s tailored to what you might want
						or the way you want to consume information utilizing all these
						technologies which are a lot cheaper to do today than they would’ve
						been three or four years ago. That will be a great, great, great
						consumption experience and is a great production experience. The
						opportunity [is splendid]. How many people here are in journalism or
						are going to go into the media? I don’t know who you have in here. I
						think sometimes people come in, and they’re like, “Oh, my God! Media
						sucks! It’s dying.” It’s not. It’s way better to be you than it is
						to be me, in terms of, because you’re coming of age, and you’re
						coming into it with this skill set that totally matches the moment.
						The moment needs, there’s going to be the boom over the next couple
						years and the need for video content’s going to be insane. Look at
						the money that not just ESPN, we have this… If you’re in media,
						you’ve got to go to axios.com, and subscribe to our media trends
						newsletter. She is the smartest person I’ve ever met thinking about
						media. She had this thing the other day about how Netflix now is
						almost tied with ESPN in terms of paying for content. Imagine if you
						had Snapchat, not paying for it, but now Snapchat will pay for it,
						but better rev shares now. Facebook’s starting to pay for a lot more
						content. All of these new distribution channels that are going to
						replace cable, and nobody knows what will replace it, but everyone
						knows something will replace it. The demand for video content,
						demand for audio content, the demand for I would still say
						text-based content, it’s only getting better. You’re growing up with
						that skill set, and you’re growing up with those consumption habits,
						so you’re actually much better positioned to help solve the future
						than somebody who’s my age or older, and grew up in a static, “I
						just read a newspaper this way,” experience. You should not go into
						the profession being bummed. You should go into it being very, very,
						very optimistic. People are, like I said, if they’re consuming 12
						hours of media content of some form each and every day, just get in
						the fight for their mindshare. That’s what it is, now. It’s now a
						battle of mindshare, that’s why you have all of these companies
						trying to diversify. Facebook, at point you hit all the people
						you’re going to hit, and you then win all of that battle to get more
						and more mindshare over the course of the day. That’s going to
						benefit the content producers, the content distributors, the content
						editors, and so it’s a great time to go and be a part of that. The
						podcasts will be part of it. Podcasts, often we chase mirages in our
						industry. We’re like, “Oh, traffic.” Then you wake up, “Actually,
						it’s not that great.” Then, it was video five years ago. Great, you
						produce a lot of video. It turns out you still couldn’t monetize it.
						Podcasts were hot. I always said, “Show me.” Yes, that’s great.
						You’ve got X-number of viewers or X-number of listeners. How much
						money you making off that? How much money did it cost you to produce
						it? If the cost of production is greater than the money you’re
						making off it, it’s not actually a profitable business. It might be
						a good investment long term. It’s essentially not a good business.
						Podcasts are still, for the most part, still there. They get a good
						audience, but there’s not a ton of money attached to it. Doesn’t
						mean that there won’t be. I think it’ll come from all of that stuff
						starting to live together and some of the stuff sorting itself out
						in terms of, we have so many content producers now, and so many
						distribution networks. Eventually, stuff will narrow itself down.
						Podcasts will definitely [still be around].
					</p>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default RandPaul;
