import React from 'react';

import '../../css/Blog.scss';
import Header from '../A-WebElements/Header';
import Footer from '../A-WebElements/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const RandPaul = () => {
	return (
		<div className="conversations">
			<Header name="blog" />
			<Container fluid className="blog-content">
				<div className="header-press">
					<h2>
						Former White House Press Secretary Josh Earnest at the Berkeley
						Forum
					</h2>
				</div>

				<Row>
					<p className="italics">
						Mr. Earnest appeared at the Berkeley Forum on October 12, 2017. Mr.
						Earnest’s talk centered around the role of innovative communications
						strategies in bolstering the presence of both the President and the
						White House in an evolving media landscape. The event was moderated
						by Shaina Zuber.{' '}
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Thank you so much for being here.
						We’re so excited to have you here.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> Thanks. I’m delighted to be here.
						It’s an honor.
					</p>
				</Row>

				<Row>
					<p>
						<strong> Shaina Zuber:</strong> I want to touch on something you
						mentioned in your speech. You described media interaction today as
						being more of a food fight than a discussion. Given your role as
						press secretary, how did this understanding of the press guide how
						you issued press statements or answered questions in briefings?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> That’s an excellent question. What
						I’m sure of is I’m sure I’m not the first White House spokesperson
						to complain about this, and I’m sure that George Washington’s press
						secretary had the same [complaint]. But what is just undeniable is
						there were countless times where I would walk out to the briefing
						room, prepared to roll out a new policy and to discuss a new
						announcement or a new idea that we were ready to put forward, and I
						would get more questions about the criticism of that policy that
						came from either left or the right than about the policy itself. I
						haven’t been there in nine months and I’m still frustrated by it.
						But that the essence of our system, and if you think about it, this
						is the vulnerability in our political ecosystem that Trump has
						expertly capitalized on, exploited. This is why he uses Twitter and
						says outrageous things on Twitter. It distracts from a bunch of
						other things that we should be talking about, and it degrades our
						political debate.
						<br />
						<br />
						So, the way that I tried to take this on was to remember that it was
						my responsibility, as an advocate for the president and as an
						advocate for the president’s policies, that when I walked into the
						briefing room, my responsibility was not to charge through the door
						and prove that all the reporters’ skeptical questions were wrong.
						And the reason that that is an unwise strategy is it’s the reporters
						themselves who are going to characterize the debate for their
						readers and their viewers, and there is never going to be a day that
						they begin their story by writing or saying, “Today, the White House
						Press Secretary proved us all wrong!” So, in the midst of an
						environment where people are focused on fighting, at least one way
						to combat that is to martial evidence, to present facts, to
						substantiate your case, and that’s what I tried to do when I
						prepared for the briefing every day, was to help the journalists who
						were in that room understand what our argument was, understand the
						basis for that argument, and to be able to write some evidence about
						why our argument just might be right.
						<br />
						<br />
						That strategy proved to work well, but not perfectly. I think there
						are a variety of things that make the White House briefing different
						now than it was before, and one of them is that when you listen to
						the people who stand behind that podium now, they’re often not in a
						position where they are making an argument. If you listen, you often
						hear lots of assertions, some of which have some basis in reality
						and some which don’t, you hear a lot of promises, some of which are
						within the realm of the possible and many of which are not, and it
						undermines their ability to be particularly persuasive. And I think
						that shows up in the coverage of the briefing, and I think it shows
						up in the coverage of the White House.
					</p>
				</Row>

				<Row>
					<p>
						<strong>Shaina Zuber:</strong> As you mentioned, the relationship
						between the current administration and the press has been fairly
						antagonistic, with President Trump using terms such as “fake news”
						to describe major media outlets. Given this, what do you think is
						the ideal relationship between the press and the administration? Do
						you think it should be more access-based, more adversarial? What do
						you think creates the most ideal situation for a democracy?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> The relationship between the White
						House press corps and the White House is supposed to be adversarial,
						and the day that it’s not is the day that the press corps stop doing
						their jobs. I made that observation frequently when people would,
						about every month or so, want to assess the state of the
						relationship between the Obama White House and White House press
						corps at the time. There were some times when it would be good, some
						times when they would be pretty frustrated that there hadn’t been a
						news conference in a while, for example, and it was my job, standing
						at the nexus of that relationship, to try to defuse that tension, or
						at least keep that tension from preventing either side from doing
						their job effectively. If the press corps was so amped up, and
						angry, and frustrated about the fact that we hadn’t had a news
						conference in six weeks, that’s going to color their coverage, so we
						need to try to find a way to resolve that. If the president and
						members of his senior staff are so exasperated by the kind of
						coverage that they’re getting from the press corps, there are some
						who may have the instinct that, well, we should stop dealing with
						them, we should stop engaging with them, or stop returning that
						person’s phone calls. That’s not an effective way for managing that
						relationship because if you don’t return that reporter’s phone call,
						I guarantee that he or she is continuing to write good stories, and
						they’re not going to make it better if you ignore them.
						<br></br>
						<br></br>So, I view that as an important part of my responsibility,
						is to try to manage that relationship. So, my job was not to
						eliminate tension or to prevent disagreements from arising. My
						responsibility was to try to manage and mitigate those disagreements
						and that tension so that it stayed at a sustainable level that would
						allow both sides to continue to do their job, and I think ultimately
						that is the kind of relationship that best serves the president and
						the White House. It’s a relationship that well serves the American
						public in terms of ensuring that there are independent, professional
						journalists on the job every day holding the White House
						accountable, holding people in power accountable.
						<br />
						<br />
						So, it doesn’t sound like a particularly ideal situation, but
						democracy is a little messy. It’s supposed to be when it’s working.
						That’s evidence that it’s working. I remember that there was one
						particularly well-respected political journalist in Washington who
						was frustrated for a reason that even to this day I don’t quite
						know, but who described the lack of transparency on the part of the
						White House as “dangerous.” Dangerous! I think we now know what the
						lack of transparency that’s dangerous looks like. It looks a lot
						different than the relations that we had.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Some of these issues of dealing with
						the press relationship included controversies surrounding the Obama
						Administration’s decision to prosecute whistleblowers under the
						Espionage Act, or decisions to not allow FOIA requests. How do you
						think this impacted your job as press secretary and the relationship
						with the press?
					</p>
				</Row>

				<Row>
					<p>
						<strong>Josh Earnest:</strong> Look, we had lots of debates about
						transparency, and this was a common point of tension in the
						relationship between the White House and the White House press
						corps. But again, if there’s ever a day where the reporters are
						sitting at the back of the room saying, “Boy, you guys have
						responded to more than 90% of the FOIA requests that we’ve
						submitted. Thank you so much for your service. We’re really
						satisfied with that.” They should be out there saying, “Well,
						where’s the other 10%?” That is their job. They should never be
						satisfied. So, I was comfortable with the critique and the
						insistence on the part of journalists that there should be more
						transparency. I think what I was not comfortable with were
						expressions like “a dangerous lack of transparency,” or exaggerated
						suggestions that somehow the White House was more hostile to
						reporters than even the Nixon Administration, something that is not
						true, but something that a number of journalists said.
						<br />
						<br />
						To illustrate for you how much of a point of tension this was, I
						actually wrote a letter to the editor of The New York Times about
						this, and the argument that I made in that letter was simply that
						this is supposed to be a point of tension between the White House
						and the press corps, but when there are things that we get right,
						there should be an acknowledgement that we got them right. There has
						to be some kind of built-in positive reinforcement mechanism if
						you’re advocating for something, and there’s nobody in the world
						that has the time to be a consistent, well-informed, persistent
						advocate for transparency other than the White House press corps,
						and you’re not going to be an effective advocate if the people that
						you are holding accountable aren’t occasionally given credit for
						getting it right. It doesn’t mean you should say you’re satisfied,
						but just the acknowledgement where progress is made is important to
						effective advocacy.
						<br />
						<br />
						If you think about this in a different setting: If you work for the
						NRA, you don’t just spend all your time beating up on the Democrats
						who don’t toe the line. You also want to look for ways to give
						credit for those who are on your side. That makes them an
						extraordinarily effective advocacy organization in Washington, and I
						think it is one way in which the White House press corps’ advocacy
						on this issue fell short and made them, in some cases, not as
						effective as they could.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Do you think this has any spillover
						effects into the press’ current relationship with the current
						administration?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> No, to be blunt. I do think that
						there might have been a situation where you could’ve made an
						argument that, again, in an alternate universe where Hillary Clinton
						was president, that you had—to be blunt about it—people in positions
						of authority who were making more rational decisions about how to
						act with the press corps, where you could basically point to the
						experience of the Obama Administration, where we got criticized even
						in those situations where we made progress. And then when that
						progress was rolled back under a Clinton White House,
						hypothetically, you could say, “Well, what argument can you make to
						the Clinton people that they should keep doing it when you didn’t
						give the Obama people credit for doing it?” But I don’t think that
						the Trump people are making their decisions based on the way that we
						did things, and in some cases the things that serve as a kind of
						tension with the press corps I think are often decisions that were
						made without any consideration whatsoever of the press corps’
						interests.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Along these lines, in an interview on
						CNN, you stated that in the position of press secretary, you and the
						recently resigned Sean Spicer had maintained a very different set of
						responsibilities. How do you think the administration or the
						president impacts the role of press secretary and how they interact
						with the press?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> That’s a great question. The
						responsibility of the press secretary is set by the president.
						You’re the spokesperson for the President of the United States, and
						you have a responsibility to perform according to the instructions
						and wishes of the president. Now, there are competing interests. You
						also have a responsibility to tell the truth. So, some White House
						spokespeople have encountered a conflict in those two competing
						priorities, but those were never competing priorities [for me].
						There was never a day where the president came into my office and
						said, “You know what, Josh, I want you to be really careful today. I
						want you to be sure to tell the truth.” He never had to say that. He
						didn’t have to say it the other way, too. He also never had to come
						into my office and say, “Now, be sure you don’t lie.” That was a
						core requirement, and that is something that starts at the top. When
						you are leading an organization, you have just as much influence
						over that organization based on what you do as by what you say. And
						how President Obama conducted himself, by holding himself to a very
						high standard, was a practice that other senior administration
						officials tried to emulate, and certainly one of the reasons that we
						didn’t have the same kind of scandals in the Obama Administration
						that other administrations have had, not just the current
						administration. And while certainly staff members deserve credit for
						that, it starts at the top, and that’s true when you’re talking
						about positive things, like avoiding scandals, but it’s true when
						you’re talking negative things too.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Thank you so much for answering my
						questions. We’re now going to offer the opportunity for audience
						questions.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> You talked about the
						responsibility of independent journalists, but I wanted to get your
						thoughts on the medium that journalists use. What role do you think
						tech companies and social media companies play as a form of the
						medium that journalists use to spread their message?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> This is a great question and one that
						is being debated in Washington, D.C., one that’s being debated in
						newsrooms all around the world—and certainly as a subject of
						concern—by people who get their news in a very different way than
						they used to even five or eight years ago. The example that I’ve
						often cited is: In the last couple of years of the Obama
						Administration, we really prioritized our effort to be more creative
						and to integrate social media tools into our efforts to inform the
						public about what we were doing. So, that meant that we would make
						news, make announcements, offer up new ideas in ways other than just
						issuing a White House news release that we emailed to our White
						House press list. Sometimes we would send tweets. Sometimes we would
						post a video or a message to the President’s Facebook page or to the
						White House Facebook page.
						<br />
						<br />
						There were times early in that transition where very sophisticated
						White House journalists would come to my office and they would knock
						on the door and say, “Josh, can I come in?” I’d say, “Sure, come on
						in.” And they would say, “Listen, I saw that you sent that tweet
						from President Obama about that announcement.” I said, “Yeah, we
						did, we did. It got retweeted tens of thousands of times.” They
						said, “If you do that again, will you send me an email to give me a
						heads up that you’ve done that?” And I said, “Well, I’ll try, but I
						can’t promise I’m going to do that.” But what that illustrates is
						even the people who are highly-trained professionals who work long
						hours and have dedicated their career to closely following what’s
						happening at the White House have a hard time keeping up in this
						news environment. It moves so quickly that even they have a hard
						time keeping track of all that’s happening, so why are we
						particularly surprised that the American public is having a hard
						time keeping up, that they are in a situation where they have a hard
						time deciphering between hard news stories and fake news?
						<br />
						<br />
						So, we do need to think hard in the midst of the dynamic news
						environment about what we can do to bolster confidence in the news
						media, and more effectively and consistently give people reliable
						information about our political system. I certainly do think that
						technology does have the potential of addressing many elements of
						this problem, but another thing that we can do is make sure that, in
						our schools all across America, that we are training sophisticated
						consumers of information. We know that we live in an age where
						information is an important element of our economy, so there’s a
						good reason to do that. We’re not just educating good citizens, but
						people who can succeed in our economy if we teach them how to
						evaluate, consume, and make decisions about information, and the
						more that we can put good thinkers in our education system or
						produce good thinkers in our education system, the better off our
						economy is just going to be. Good question.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Cody Keenan talks about that week
						in 2015 where the Affordable Care Act was upheld by the Supreme
						Court, same sex marriage was legalized in all 50 states, and then
						the president sang Amazing Grace [after the shooting in Charleston].
						So, not counting when the royals visited the White House, is there
						any week that stands out for you like that one stands out for him?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> You’ve done your homework. For those
						of you who don’t know, Cody Keenan was the president’s head speech
						writer for the last three years that he was in office. Cody is an
						extraordinarily talented writer, and he’s very clever on Twitter, so
						if you don’t follow him I recommend you do so.
						<br />
						<br />
						The week of the election was a remarkable week in so many different
						ways and for so many different reasons. I was shocked by the outcome
						and so many of my colleagues in the White House were as well. I
						remember sitting on the sofa in my living room, watching the returns
						come in. I was sitting there with my wife and she, every 20 minutes
						or so, would look at me and she’d say, “You nervous yet?” And I’d
						say, “Nah, it’s going to be fine. The votes are still coming in.” I
						live in the state of Virginia. We voted in the state of Virginia.
						Virginia was one of those places where Trump started out with a big
						lead that was very disconcerting to a lot of people, but over the
						course of the evening Hillary made up ground and steadily eroded his
						margin, and actually ended up winning the state. And I
						expected/hoped that we would see a similar phenomenon in Michigan,
						and Ohio, and Pennsylvania, and Florida, and when it became clear
						that we were going to need to see that situation replicated in all
						of those states at the same time, then I knew it was time to worry.
						<br />
						<br />
						But look, what was remarkable about that week was the day after the
						election. I stayed up late the night of the election, knowing that I
						was going to have to do the daily briefing the next day and answer a
						lot of tough, pressing questions about the rhetoric that President
						Obama had used on the campaign trail. He had said that he did not
						have confidence that the country would be safe with the nuclear
						codes in the hands of Donald Trump. He said that he’d pose a threat
						to our democracy. He said that Trump was likely to be in a position
						to succeed in rolling back a whole bunch of the progress that we’d
						made with the Obama Administration. He’d painted a pretty bleak
						picture about what could possibly happen if Trump was elected
						president – and then Trump was elected president. I also anticipated
						getting questions about how we could be so wrong given that Trump’s
						entire political identity was rooted in opposing Obama. I
						anticipated a lot of questions about whether or not this was a
						pretty stark rebuke from the American public to the Obama White
						House about what we had done.
						<br />
						<br />
						So, it was a hard day and one that was physically demanding and
						emotionally demanding, but I took a lot of inspiration from the
						president speaking in the Rose Garden that afternoon after Secretary
						Clinton had conceded. President Obama went out to the Rose Garden
						and said that history doesn’t move in a straight line, progress
						doesn’t move in a straight line, and there’s zigs and zags, and
						occasionally we move back before we move forward, and that’s why
						it’s important to take the long view and to keep a long-term
						perspective in regard to the trajectory of our country. He expressed
						his commitment to ensuring a smooth transition to the incoming
						administration. It reminded me of the notion that in countering and
						overcoming adversity, it doesn’t build character necessarily, but it
						certainly reveals it, and I thought President Obama revealed a lot
						of character in that moment, and I tried to live up to the standard
						that he set when I moved to the Briefing Room later that day.
						<br />
						<br />
						The other thing that has been reported about that day is that
						President Obama went around the White House to talk to his White
						House staff and to buck them up, to encourage them, to console them.
						He showed incredible leadership that day, on a day that must have
						been very difficult for him, and he did most of that work in
						private. It revealed, to me, yet again, the high-quality character
						that he is.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> My question is: The reputation of
						D.C. is [that] everyone inside the Beltway knows everybody, hangs
						out with everybody. I’m curious what your relationship with the
						press is like on a personal level. Do you have friends or drinking
						buddies? Were friendships gained or destroyed before, during, and
						after your time in the White House?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> Good question. What I will tell you
						is that I, like many people in Washington, have friendly
						relationships with reporters. It’s particularly interesting now
						because I’m employed by a news organization; I’m a contributor to
						NBC News. But the thing that I constantly remembered when I was at
						the White House is that it is entirely appropriate—even my
						responsibility—to be friendly with reporters, but let’s also
						remember that reporters are not your friends.
						<br />
						<br />
						We were staying about an hour away from the summit site because of
						security restrictions, because all the world leaders were attending
						the summit. So, we stayed at a hotel in Bali that had a very nice
						swimming pool that overlooked the ocean, and as was customary,
						President Obama left the summit before the press corps because the
						press corps would often want to stay—the TV reports in
						particular—and do a news report on location in Bali, summarizing the
						President’s trip. So, the timing worked out that we stayed for six
						or eight or ten hours after President Obama had left Bali, and we
						were staying at this hotel because of the time difference. It was
						during the day in Bali. President Obama was somewhere over the
						Pacific Ocean on a plane. Nobody had any practical, functional
						responsibilities, so it meant that you had White House press staff
						and all these reporters swimming in the swimming pool overlooking
						the Indian Ocean in Bali. So, yeah, there were times where I get my
						shirt off and I’m in the swimming pool hanging out with these
						journalists who were in their clothes too. When in Bali!
						<br />
						<br />
						But the thing that I can tell you is those close quarters we were
						working in meant that, yeah, you developed a friendly relationship
						with the reporters, but you get back on that plane and you get back
						to the United States of America, and you’re on opposite sides again.
						And that adversarial relationship, even if it was sort of suspended
						in unique moments like that, I’m not at all under the impression
						that anybody ever went easy on me because we had a couple of hours
						in the sun in Bali. Good question.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> I was just wondering, with a White
						House Secretary who has to find that balance, like you said, between
						truth and sometimes what the press is trying to convey, what is your
						advice to [Sarah Huckabee Sanders]?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> Tell the truth. That really is it.
						Look, there are situations where it can be really hard to take on a
						question that basically everybody knows the answer to, and there is
						a way to present a case that can shape the answer to the question
						that doesn’t require you to mobilize false information. So, let me
						give you a good example of that.
						<br />
						<br />
						In 2014, President Obama was, in many ways, at the nadir of his
						political influence, and this is not uncommon for presidents after
						six years that their influence starts to wane. Congress had been
						particularly dysfunctional. Republicans had majority in the House
						and they were using that to really flummox any effort that we were
						making to move forward. Democrats were trying desperately to try to
						hang on to their majority in the Senate, and of course they did not,
						in part because President Obama was not particularly popular at that
						point. And in the run up to that election, I got a lot of intense
						questions about: Isn’t it true, Josh, that President Obama doesn’t
						have the kind of political influence that he used to have, even
						among Democrats, and the Democrats who are running for reelection
						don’t want to be seen with the President of the United States and
						they don’t want him to come campaign for them in their home states?
						<br />
						<br />
						And of course that was true! Anybody who knows a thing about
						politics knew that was true, but I wasn’t going to walk out there
						and say, “Yeah, the President just doesn’t have much stroke and all
						these Democrats are probably going to run from him.” What I did was
						I went out there and said, “The truth is President Obama continues
						to have a lot of influence over Democrats, and President Obama has
						put forward a message that a lot of people continue to support. And
						while the public approval rating of President Obama’s performance as
						president is lower than it has been in the past, the approval of him
						as a person is still pretty much higher than any other person
						involved in American politics right now.”
						<br />
						<br />
						So, it is possible to make an argument to try to shape the answer to
						the question that everybody already knows the answer to without
						standing up and saying, “Of course that’s not true! We’ve turned
						down all kinds of invitations to go campaign places,” which seems
						like the kind of answer you might here in this environment. But, of
						course, it was not true. That’s an excellent question. Thanks for
						asking.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Do you think that democracy is the
						end or a step towards something greater?
					</p>
				</Row>
				<Row>
					<p>[Laughter]</p>
				</Row>
				<Row>
					<p>
						<strong>Josh Earnest:</strong> You have learned something that many
						White House journalists have not learned, which is that there is an
						inverse proportional relationship to the length of a question and
						how easy it is to answer. If a reporter was going to go on and on
						and ask a 90-second-long question, it was going to be pretty easy to
						answer, because they’re opening up all these avenues for responding
						to them. But when you ask a question as directly as you have, people
						are going to be listening carefully to the answer to see if you’ve
						answered it. So, he’s got a bright future in journalism if he
						chooses to pursue it.
						<br />
						<br />
						I’m pretty optimistic, and I think that it is possible for the
						democracy that we have in this country right now to improve and to
						get better and to get stronger. And I think the healthiest
						democracies are the ones that are constantly evolving, constantly
						reinventing themselves, either in response to outside forces or in
						response to the preferences of their citizens. Technology can shape
						it, the issues that we’re asking the government to confront can
						shape it, and whether that’s climate change or a conflict with
						another country, the best democracies are the ones that are going to
						be responsive to those outside forces and responsive to the needs of
						their citizens. And that’s not just going to shape the debate; it’s
						going to shape the essence of our government.
						<br />
						<br />
						And I think part of the problem we have right now is that our
						democracy is pretty brittle, that we sort of keep bumping into the
						same kinds of constraints that have prevented us from doing things
						that are pretty obvious, things that are strongly supported by a
						vast majority of the country. A strong majority of Americans support
						things like comprehensive immigration reform, a smart investment in
						our infrastructure, common sense gun control. There are things that
						we can do, but our brittle democracy right now is preventing those
						things from happening, and what we need is we need our democracy to
						adapt and start electing people to office that aren’t just looking
						to pick a fight, but are actually looking to consider ideas where we
						can find some common ground.
						<br />
						<br />
						This is a good way to end. It goes back to that argument that I was
						making before. It’s easy to get attention for yourself if you can
						engage in a fight and colorfully criticize and trash your opponent
						or somebody on the other side. It takes a little more work to try to
						think of a new idea or a creative solution, and then work with
						somebody who may not totally agree with you to find some common
						ground on that idea. And the way that we’re going to get to see that
						change is if people who are in elected office feel pressure from us
						to choose that path, and I think that’s the sign of a healthy
						democracy, and I’m optimistic that at some point… I don’t know if
						it’s going to be the next election, in the midterms, or if it’s
						going to be the next presidential election, or where down the line,
						but at some point we’re going to make that adaptation and we’re
						going to be better for it. It is authoritarian governments that
						don’t adapt very well. People who are in authoritarian positions of
						power are not used to responding to pressure and making changes, but
						that’s the essence of our democracy, and so I am optimistic that our
						country will continue to be a democracy. But if our democracy is
						going to be healthy and succeed, it’s going to continue to evolve.
						Great question.
					</p>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default RandPaul;
