import React from 'react';

import '../../css/Blog.scss';
import Header from '../A-WebElements/Header';
import Footer from '../A-WebElements/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const RandPaul = () => {
	return (
		<div className="conversations">
			<Header name="blog" />
			<Container fluid className="blog-content">
				<div className="header-cohost">
					<h2>Co-Host of the Young Trks Ana Kasparian at the Berkeley Forum</h2>
				</div>

				<Row>
					<p className="italics">
						Ana Kasparian appeared at the Berkeley Forum on November 10, 2016.
						Ms. Kasparian’s address centered around how her career has informed
						her passions for issues such as feminism and education reform. The
						event was moderated by Jackson Rigley.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jackson Rigley:</strong> I wanted to first ask you about
						your time at [The Young Turks], you’ve been working there since 2007
						so obviously you’ve seen a lot of political change over the years,
						factions rising and falling. My question is, how has the political
						landscape changed while you’ve been reporting and how has that
						changed you?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> The political landscape, I would
						say, hasn’t really changed for the better. I’m actually pretty
						worried about it considering how this election went down. Now, for
						those who are supportive of Donald Trump, I would hope that you
						don’t at least agree with the rhetoric. But I think that this
						election has really opened the floodgates for some scary times and I
						think that we need to take a step back and think about the way that
						we perceive one another, the way we disagree with one another, the
						way we interact with one another. I’ve seen politics become more
						corrupt, which I think has led to the backlash toward candidates
						like Hillary Clinton. And what we need to do more than anything,
						whether you’re Republican or Democrat, is fight to take our
						democracy back. I think the biggest issue for everyone who’s
						interested in politics, for everyone who cares about our democracy
						is getting that money out of politics. And it’s actually the most
						non-partisan issue impacting us right now. There are Republicans and
						Democrats right now that see this as a big problem, and they want to
						do something about it. So I would say that politics has definitely
						become more corrupt and with this election, it’s gotten bigger.
					</p>
				</Row>

				<Row>
					<p>
						<strong> Jackson Rigley:</strong> This corruption – you’re referring
						to Citizens United as being a part of that – so what kind of
						solutions then do you bring about? Because obviously there are
						constitutional difficulties with freedom of speech and trying to
						overturn the Citizens United ruling, so what is the best way of
						bringing about this change?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Well, I don’t agree that
						corporations are people – I think that’s a ridiculous notion. So I
						don’t think that this is a freedom of speech issue. Now, with that
						said, what I think is important is that we have public financing of
						elections. So one proposal – and I’m personally tied to this – is to
						create a constitutional amendment to get money out of politics.
						There’s a political action committee known as Wolf PAC that TYT has
						launched. What we want to do is to get state representatives to
						agree to a constitutional convention where there will be dialogue
						about how we move about with a constitutional amendment. And I think
						it’s doable. States like California, I believe Vermont as well, have
						already signed on for this, so people get it. And this is the good
						thing about this – it’s not a partisan issue, so it’s not like we’re
						having a debate about abortion. We’re not having a debate about gay
						rights. We’re having a debate about democracy. And so Democrats get
						it, Republicans get it, Independents get it, the Green Party gets
						it, everybody gets it. We’ve actually had Republican state
						representatives sign off on this, which is great. It’s a long road
						ahead of us, and sometimes we feel discouraged because we launched
						this back in And even if it’s a slow progression, I do see us moving
						towards progress, because people are tired of corruption. And again,
						I think the election of Donald Trump shows that, even though he’s
						someone as I mentioned earlier, who benefits from that corruption
						quite a bit.
					</p>
				</Row>

				<Row>
					<p>
						<strong>Jackson Rigley:</strong> Now in your address, you’re very
						critical of mainstream media which has a lot of influential
						advertisements. I’d like to ask you, as a political commentator,
						what value do you bring to the democratic process. Does partisan
						commentary work to further dogma?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Yeah, I come from a progressive
						point of view and I think the important thing is to always be honest
						with your audience. It doesn’t matter if you’re watching CNN or The
						Young Turks, you are getting a point of view. The question is are
						you getting a point of view that’s open and honest and transparent
						or are you getting reporters who are pretending to be objective.
						There’s a difference between being objective and being neutral. And
						what you see a lot in the media is this confusion – or confusion
						isn’t the right word. You see reporters attempting to be objective,
						when in reality, they’re just treating both sides as if they’re
						equal when they’re not equal. I’ll give you a quick example. I was
						watching Hardball with Chris Matthews on MSNBC today, and I don’t
						know why I do this, but whenever I’m traveling for work, I love
						watching the mainstream media. It’s because I don’t have cable, like
						I won’t pay for cable, it’s just not worth it.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jackson Rigley:</strong> When you’re alone in a hotel room,
						it’s nice to have a voice.
						<br />
						<br />
						[audience laughter]
					</p>
				</Row>

				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Yeah, it’s nice to have a voice, and
						I love to watch and get angry at the TV. So, I’m watching Hardball
						and Chris Matthews is supposed to be a liberal, but he had this
						montage where he was comparing Donald Trump and Barack Obama. Now,
						they had their meeting today, and a lot of people are confused about
						it, whether they get along or what happened. And Chris Matthews was
						like how could they possibly get along? Look at the terrible ways
						they attacked one another. And so you see Trump, and Trump’s like
						“Show me your birth certificate! You’re black, you can’t be an
						American!” – he didn’t say that, but that was what was implied. And
						it was hideous, Donald Trump is pretty brutal and hideous to him.
						And then, Obama’s attacks were “This is unacceptable rhetoric, we
						can’t talk to each other this way, he’s trying to divide us.” It was
						just like a completely different way of dealing with someone. And so
						the way the montage was put together, it had made it seem as though
						they were both equal – but they’re not equal to each other. They’re
						not both equal. I’m not a big fan of Barack Obama, i think that in a
						lot of ways, he has failed us. But, to say that he uses the same
						tone and the same rhetoric as Donald Trump in attacking Donald Trump
						is ridiculous. And so, that kind of neutrality is unacceptable in my
						book. You need to be a truth-teller.
					</p>
				</Row>

				<Row>
					<p>
						<strong>Jackson Rigley:</strong> Now, before we speak more about the
						election, because obviously that’s something we will get to, I have
						to ask you a little bit more about the name of the show, Young
						Turks. Because as the group that committed the Armenian Genocide
						where more than 1.5 million people died in 1915, can you explain to
						me a little bit more about this name or what I’m missing, because it
						almost seems if someone because they enjoy states’ rights uses the
						Confederate flag as a symbol. Can you explain that?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Yeah, so the Confederate flag or a
						word like Nazi means one thing. So a lot of people like to attack us
						and say, “Oh it’s just like naming your show the Young Nazis.” But
						the English definition of Turk, Young Turk, is someone who rebels
						against societal expectations. I’m Armenian, and I get it, Armenians
						are angry with the name of the show. I can be as honest as I want, I
						hate the name of the show. I despise the name of the show. If I
						could do anything in the world right now, I would snap my fingers
						and the name of the show would be different. Unfortunately, the name
						of a show is a brand, and once you change it, the brand disappears.
						And so, we’ve had so many talks about this, so many tears have been
						cried, so many difficult moments have been had, and it’s been
						difficult for me personally. Now, that said, the name of the show is
						not meant to hearken back to a genocidal group – that would be a
						really bad business idea. You know the intention of the name is,
						right – we want to be rebels, we’re rebelling against societal
						expectations. What a lot of people don’t even realize – which is
						what I find interesting – is the Young Turks originally arose to
						challenge the Ottoman Empire which was horrendous to Armenians. And
						so, at first, they actually wanted to give Armenians more rights
						because they were disenfranchised and treated horrendously by the
						Ottomans and that’s how it started out. But then there were rebels
						within the rebels. There were rebels within the Young Turks that
						wanted to continue being horrendous to the Armenians, and so, that’s
						the group that of course, that I hate, I despise beyond words could
						even explain or describe. My family was tortured, brutalized by
						Turks and so it’s a difficult issue for me to even talk about
						without getting emotional. But I just want to assure you guys we
						have no intentions of denying the genocide, I’ve talked about the
						genocide on the show. There are videos about the Genocide on the
						Young Turks’ channel. I was able to do those videos with Cenk’s
						blessing so I encourage you guys to check that out, but I hear you,
						I totally understand where our viewers are coming from.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jackson Rigley:</strong> You’ve been very outspoken, in that
						regard, is it something that you feel like is reasonable that people
						are still reticent of the name? Do you get maybe some more insight
						into how commercial imperatives for other markets, and for other
						newspeople play into their decision-making? Because you know keeping
						to the name because of brand recognition, there are other
						imperatives around media. Does that give you insight?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> We’re not really worried about other
						members of the media or anything like that. We’re more concerned
						about not disappearing because we’ve worked so hard to build what we
						have and changing the name means that we disappear. That’s why we
						try to refer to us as TYT, every time I introduce as the host of the
						Young Turks, I kind of cringe a little bit, if I’m going to be
						honest with you. But I had to make a decision and ultimately my
						decision was to stick with the show that didn’t censor me and gave
						me a voice. That’s more important to me right now.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jackson Rigley:</strong> I really appreciate you’re honesty,
						I have a billion questions to ask you, but I think we only have time
						for one more so the audience can have the chance to ask you their
						questions before you get out of here. I think that a lot of people
						are wondering about what role gender played in this election. And
						why do you think so many women supported Donald Trump?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Yeah, it’s interesting. There was
						certainly a split in female demographics that Hillary supported. And
						so, Donald Trump did do well with white female voters, which I think
						is telling. And Hillary Clinton did well with minority women.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jackson Rigley:</strong> Why do you think that’s so?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> I mean it’s telling considering the
						rhetoric that Donald Trump has used throughout his campaign. I think
						even his strongest supporters could agree that some of the things
						that he’s said about minorities has been offensive, and beyond
						questionable – talking about banning Muslims, referring to Mexican
						immigrants as rapists and criminals. I mean that kind of language
						turns a lot of minorities off. But I think the reason why a lot of
						people were kind of just able to brush that kind of rhetoric under
						the rug and vote for him anyway is because they are tired of the
						status quo. I don’t think that all Trump supporters are necessarily
						bad people so I don’t want anyone to think that I have that frame of
						mind. I think some of them, that we’ve interviewed during our field
						reports are very clear about their bigotry and very honest about
						their bigotry which concerns me, but I think that a lot of the
						people that voted for him really believed that he’s
						anti-establishment and he’s really going to change things. There are
						people that feel the economic burden of failed policies, but again
						if you want to focus on the mainstream media, his economic proposals
						have a lot to do with trickle down economics and we’ve had a very,
						very, very long experience with that. And it hasn’t worked. And so
						I’m curious to see how it all plays out. Who knows? Maybe he will
						make America great again.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience member:</strong> Thank you so much for being here.
						There’s been a lot of talk recently about social media acting as an
						echo chamber that’s feeded into polarization of beliefs. Can you
						comment on that?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Absolutely. So I’ll actually talk
						about it in a more broader sense and how much of an impact it has on
						everyone regardless of what you’re political ideology is. So in a
						journalism class that I teach at Cal State Northridge, a lot of the
						lessons that I discuss is the echo chamber of the Internet.
						Particularly Google. So everytime you’re searching for something on
						Google, or every time you go on to one of your favorite websites,
						whether it be a conservative website or a liberal website, Google
						keeps track of all that information. We know that. And the reason
						they do it is because they want to perfect the algorithm to better
						suit you, so that you’re always exposed to information and content
						that will always appeal to you. But there’s a huge downside to that,
						because your entire life becomes an echo chamber. We tend to
						associate with people we agree with anyway, but the Internet is
						supposed to be a place where we go, where we have unlimited
						resources to educate ourselves. We’re supposed to have an open mind
						and be exposed to varying opinions and varying perspectives. And
						Google, in its effort to kind of cater to us, has actually deprived
						us of different perspectives and I think that’s polarizing American
						politics even more. And you see it in social media because, again,
						we tend to be friends with people we agree with. But I would argue
						that it’s more important to be open-minded to people that you
						disagree with. Now again, rhetoric matters, so if we’re attacking
						one another and we’re calling each other names while we disagree,
						that’s only going to further divide us. Look, I’ve been guilty of
						that as well, so I’ve got to be honest, but everyone in a while
						after my rage relaxes and I tone it down, I try to think about the
						conversation I just had and I try to think about whether or not that
						other person had a point. Best example is when I was debating about
						prostitution and whether or not we should legalize it, with Cenk
						Uyger, the host of our show. And he’s in favor of legalizing and
						regulating it and at that time, I had bought into the conservative
						propaganda and I’m like “No! You legalize it and there’s going to be
						prostitutes everywhere!” And we’d gotten into a huge fight about it
						– I was super stubborn, I wouldn’t agree – I was like “No! You’re
						wrong! You’re wrong! I don’t want to brothels right next to schools!
						It’s going to be terrible!” And then I started secretly on the down
						low, doing my own research, like what the pros and cons, what’s
						happening in other countries that have legalized prostitution. And I
						started changing my mind. And all I ask is that we all keep an open
						mind, let’s go out of our way to expose ourselves to the other
						perspectives.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> If you look at independent,
						media-based kinds of resources that are customizable, specifically
						the Young Turks, you guys target a very much more progressive
						audience which seems to contribute to this kind of echo-chamber
						effect, so do you feel that your outlet is at all responsible in
						part for this kind of polarization? How much of the burden of
						otherwise Donald Trump for example, are you willing to take for
						leading to this polarization?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Yeah, that’s a great question
						actually. So I would like to do more research into our audience to
						figure out what portion of it does consist of conservatives but we
						shockingly have a lot of conservatives watching the show. And the
						reason why that happens, in my opinion, is because we do attack
						Democrats a lot. Because of the corruption and the politics,
						Democrats and Republicans behave very similarly once they’re elected
						into office. And so we talk about that a lot, and so, like I said
						earlier on during my talk when President Obama was elected and we
						started criticizing him, people who wanted the echo chamber started
						dropping like flies and it sucked. We felt like we made a lot of
						progress with the show in 2008, like oh, we grew our audience, we
						thought our audience was great and then all of a sudden our
						subscriber base just dropped. It didn’t just stagnate, all of a
						sudden you see a huge dip. And you also saw that dip during this
						election when Hillary Clinton was nominated and we had to keep it
						real with the audience and be like, “Hey, Hillary Clinton is now the
						Democratic nominee.” And we had varying voices and a lot of
						disagreement on our network about whether or not we should vote for
						a third party, whether or not we should support Hillary, is it
						better to have Trump. And the theory behind that was well if you
						elect Trump, he’s going to burn the country to the ground and start
						again. Literally, there were people on our network that have
						theories like that. And so, there was a lot of disagreement on the
						echo chamber, just because we come from a progressive point of view
						doesn’t mean we totally agree with one another so we really go out
						of our way to make sure we don’t have an echo chamber and I think
						our audience also reflects that.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience member:</strong> I’m a huge fan of your show, and
						the feed on Tuesday was one of the few things that kept me sane.
						With the recent subcommittee note on corruption and the Democratic
						party, I’m curious about your level of optimism about if the DNC is
						really able to make some change and allow true progressives who
						aren’t accepting their money to come in and change the Democratic
						party?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> So I don’t know if you guys saw, but
						Howard Dean would now like to be the head of the DNC, which I think
						is not a good idea because he’s a gigantic lobbyist and that’s not
						what we need. But I think that TYT as an organization is very
						optimistic about leading the way to change for the DNC. The DNC is
						very corrupt. What happened with the primaries, it irates me beyond
						belief. Like it was the hardest thing to stomach, to see what the
						DNC did, to see what Debbie Wasserman-Schultz did in manipulating
						the primaries to ensure that we had a flawed candidate as the
						Democratic nominee and that ultimately led to Trump winning. And so,
						what we want to do is we want to fight and Cenk is a little more
						aggressive in his wording, but he wants to go to war. And a lot of
						people are like “Oh, it’s a liberal echo chamber,” but we want to go
						to war with liberals, specifically members of the DNC, we want to
						change the DNC and we want to do it aggressively. We want people
						like Bernie Sanders to be part of the DNC, people who refuse to take
						corporate money, people who understand that corporate greed is
						unacceptable in a place that’s supposed to function as a democracy.
						And so, we’re working on a game plan right now, obviously it’s a
						very early for me to give you any details, but we’re going to fight
						and we’re going to fight hard.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience member:</strong> I think it’s safe to say that most
						liberals really did not see the result of this election coming, and
						one cause of this might of course be because of the whole echo
						chamber effect, do you also think that there’s something wrong in
						the way we conduct our polling?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Yes, think that a lot of the
						organizations that do the polling are part of the echo chamber, and
						I think that they’re so caught up in their establishment bubble that
						they just don’t believe that someone like Donald Trump could ever
						win the presidency. And so, that bias, I think, impacts their
						methodology. And there was also the issue of people not being honest
						about who they’re going to vote for. Or maybe not being honest about
						whether they’re even going to go vote. There are a lot of people who
						didn’t vote, a lot of members of our electorate who just decided to
						stay home and it might be because they were tired of this system
						where you have to pick the lesser of two evils. You’ve heard that
						quite a bit. So I think that if I ever hear Nate Silver or
						FiveThirtyEight again in a credible, legitimate way, I will strangle
						myself. I don’t know how often you guys watch the show or how
						regularly you watch the show, but I don’t care about polling. Like I
						care about it a little bit, to kind of get a sense of what people
						are thinking, but I don’t put too much weight behind it. There are
						other members of our show, who love FiveThirtyEight, but I don’t
						care what Nate Silver says. Instead of focusing on polling, why
						don’t we focus on policy? Policy over polling – that’s what
						elections should be about. What are these people proposing to do for
						us? Like forget about their character, forget about whether or not
						they’re unsavory. What are you going to do, Donald Trump? What are
						you going to do about foreign policy? “Oh, I’ll let you know when
						I’m elected” – no, that’s not good enough. That’s what we should
						focus on. So, i think that yes, the pollsters are part of the echo
						chamber, and I think that hopefully, after this election, they
						experience a little bit of humility and they realize that they need
						to get themselves out of that bubble.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jackson Rigley:</strong> In fairness to Nate Silver, I think
						we’d still count him for sports. I think that’s all of it.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ana Kasparian:</strong> Sure, as long as it doesn’t have to
						do with our democracy.
					</p>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default RandPaul;
