import React from 'react';

import '../../css/Blog.scss';
import Header from '../A-WebElements/Header';
import Footer from '../A-WebElements/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const RandPaul = () => {
	return (
		<div className="conversations">
			<Header name="blog" />
			<Container fluid className="blog-content">
				<div className="header-rand">
					<h2>US Senator Rand Paul at the Berkeley Forum</h2>
				</div>

				<Row>
					<p className="italics">
						US Senator Rand Paul appeared at the Berkeley Forum on March 19,
						2014. Senator Paul discussed the implications of NSA’s surveillance
						activities on liberty and his potential plans to run for the 2016
						Republican nomination for President. The event was moderated by
						Matthew Freeman.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Matthew Freeman:</strong> Conservative activist, Larry
						Clayman, and the ACLU have also filed similar lawsuits against the
						NSA. And both resulted in either failure or a stayed ruling. What
						makes you think that you’ll have any more success than these groups
						that have tried before you?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Rand Paul: </strong>I am supportive of all the other
						lawsuits, so it isn’t exclusive that mine is the best, but it is
						slightly different. The ACLU lawsuit was ruled against. The judge
						either threw it out or said that the program was constitutional. The
						Clayman suit is in the same court that mine will go to. And the
						judges previously ruled it unconstitutional, stayed the ruling, and
						I think it will be appealed. So, I think the Clayman suit is still
						active. Ours is going to the same court because it has a similar
						subject. Our case is slightly different, and we think, for some
						legal reasons, that it may have a change of going all the way to the
						Supreme Court.
					</p>
					<p>
						To me, it’s not so much that my case has to go, but I think a case
						needs to go to the Supreme Court. Because currently, many people
						believe that the Fourth Amendment doesn’t apply to all. They think
						that the reason why you can give a single warrant to Verizon is that
						you don’t own those records. I think they’re jointly held. I think
						if you sign a privacy agreement, Verizon agrees not to tell your
						neighbor who you’re calling. So, they kind of acknowledge that. I
						think it’s acknowledging that you still have an interest in those
						records.
					</p>
					<p>
						But to me, the most important thing is… And there’s at least, we
						think, four or five Supreme Court justices that have indicated that
						in this digital age, think about it, it’s a lot different than 1975.
						That’s when the last case, Smith versus Maryland, was held on
						records. It’s also different. That was about one suspect’s phone
						tap. We’re now talking about 300 million Americans’ phones. So, I
						think it’s a big deal, and it is different than what we’ve ever had
						before. So, I’m hoping that we will get all the way to the Supreme
						Court.
					</p>
				</Row>

				<Row>
					<p>
						<strong> Matthew Freeman:</strong> So, earlier, you condemned
						director of National Intelligence, James Clapper, for allegedly
						lying in front of Congress. You said he’s very explicitly broken the
						law. Does that mean that you think he should be sent to prison?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Rand Paul: </strong>I think he should be. You don’t get sent
						to prison until you’re found guilty. So, we should have a trial. He
						deserves a trial. But the interesting thing is I’m not an outlier on
						this in the sense that I think seven members of the Intelligence
						Committee or Judiciary Committee in the House have signed a letter
						saying the same thing. And I think it hurts us because we do have to
						rely on some things being secret. And it’s an extraordinary power.
						It’s a power to capture people, incarcerate people. It’s even a
						power to kill people. So, that power needs to be overseen, and they
						have to be honest with us. If the people in charge of the
						Intelligence Committee are not being honest to Congress, and they’re
						actually spying on Congress, I have grave doubts about everything
						they’re telling me.
					</p>
					<p>
						So, yeah, I think it is important. And one of the reasons I bring it
						up is that many of these people, they want to throw the book at
						Snowden. And I have mixed feelings what should happen, because I
						think you can’t release secrets all the time. That would lead to
						chaos. But at the same time, I think he also wanted to reveal
						something he thought was unconstitutional. But for all the people
						that want to throw the book and the letter of the law at Snowden, I
						like the contrast. They don’t want to do a thing…not a peep out of
						them for Clapper. So, you’re not really being consistent if you want
						to throw the book at Snowden, but you don’t want to do a thing to
						Clapper. They both broke the law technically. And then you have to
						decide what justice is. But yeah, I think Clapper should be tried
						for perjury.
					</p>
				</Row>

				<Row>
					<p>
						<strong>Matthew Freeman:</strong> So, you say you’re asked this all
						the time, but we want to get in here, too. Would you classify Edward
						Snowden as on the one hand a hero or a traitor? And to phrase that
						slightly differently maybe, if there were another Edward Snowden out
						there, would you encourage him to speak up?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Rand Paul: </strong> I think the ultimate decision of hero
						or villain in history is going to sort out. And I think there are
						pros and cons to a lot of it. And I know people have a strong
						feeling about it. I think that his intentions were good. But here’s
						the problem – let’s say we have 400, 500 people here, and let’s say
						you all are… We’re talking to you, and you’re the new recruits for
						the CIA or for the intelligence for our army. Should I tell all 500
						of you, “Just decide when you think it’s unconstitutional and just
						reveal secrets any time.” You could see how it could lead to chaos.
						But at the same time, I’m very upset about what our intelligence
						community is doing, and we might not have ever known about it had
						Snowden not leaked it. Some say Snowden should have tried to become
						a whistleblower.
					</p>
					<p>
						I don’t know if he did try or what the process is. But I think on
						the one hand, you have chaos. Bradley Manning released 24 million
						pages. There’s a chance that people could die from that. There’s a
						chance that intelligence could get out, and it could endanger our
						agents. And I’m not against spying. We will have people gathering
						intelligence around the world. And I don’t think that we can allow
						willy-nilly indiscriminate release of documents. But at the same
						time, I’m sympathetic to what was released, because I think it’s a
						real problem. So, I have mixed feeling is the bottom line.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Matthew Freeman:</strong> So, you posed a very interesting
						question during your address. You asked about potential CIA spying
						on Senate computers. To quote you, “If the CIA is spying on
						Congress, who exactly can or will stop them?” So, what would be your
						answer to this question?
					</p>
				</Row>

				<Row>
					<p>
						<strong>Rand Paul: </strong> Well, see, here’s the interesting
						thing. And this is worth everybody reading about. The way I
						understand it… And this is what Senator Feinstein said in her
						speech. They came across something… They were given access to the
						CIA computers by the CIA. The search engine was created by the CIA.
						They say… And I’m just going from what they’re telling me. They say
						they found a report called the Pennetta Review which looked into
						some previous activities in the CIA – interrogation and detention.
						And they got it through the search engine. If that’s true, the CIA
						then may have said, “Oh, whoops. We didn’t want you to read that.”
						But think about that. If it was a mistake by the CIA, you can say,
						“Well, that was a mistake.”
					</p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>

				<Row>
					<p></p>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default RandPaul;
