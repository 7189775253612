import Gig from '../../img/EventCards/GigEconomy.png';
import Ralph from '../../img/EventCards/Ralph.png';
import Majo from '../../img/EventCards/Majo.png';

export const currentEventData = [
	[
		'Dr. Ralph Basilio',
		'Facebook Live: Dr. Ralph Basilio at the Berkeley Forum',
		'October 13, 2020 | 5:00pm - 5:45pm | The Berkeley Forum Facebook Live',
		'Basilio joined NASA in 1989 and started working on the mission as its Deputy Project Manager in 2010. Over the last 60 years or so, we have discovered that only about half of the carbon dioxide we emit into the atmosphere stays in the atmosphere. Where is the other half going? Is it being absorbed by the oceans or vegetation on land? The answer is critical if we’re to fully understand the future climate change in store for us and the impact of our emissions. The flight of OCO-2 will unveil new answers and settle unfinished business.',
		'',
		Ralph,
		'https://fb.me/e/ky7wtrVbM',
		'',
	],
	[
		'Majo Molfino',
		'Facebook Live: Majo Molfino at the Berkeley Forum',
		'October 15, 2020 | 5:00pm - 5:45pm | The Berkeley Forum Facebook Live',
		'She is  the author of the #1 New Release on Amazon (Inspiration & Spirituality; Feminist Theory) Break the Good Girl Myth with HarperOne/HarperCollins.',
		'',
		Majo,
		'https://fb.me/e/1b8FfJZY3',
		'Can UC Berkeley have an in-person spring semester? Will commencement be virtual? Perhaps, most importantly: When will the COVID-19 pandemic end? These are some of the questions top-of-mind for college students amidst the current crisis.',
	],
	[
		'Gig Economy Debate',
		'Professor Alexandra J. Ravenelle and Assemblywoman Lorena Gonzalez',
		'October 18, 2020 | 5:00pm - 5:45pm | The Berkeley Forum Facebook Live',
		'Professor Alexandra J. Ravenelle (UNC-Chapel Hill Sociologist & Gig Economy Researcher) and Assemblywoman Lorena Gonzalez (Co-author of the AB-5) discuss the gig economy, and whether it is sustainable and benefical for individauls who do not want to be "tied-down" to any specific job.',
		'With business stratagem and technological advancements, we have seen the rise of gig economy companies such as Uber, Lyft, and DoorDash. 57 million people participate in the gig economy and rely on these forms of monetary compensation for a variety of reasons. Recently, California passed the AB5, which extends employee classification to gig workers and removes their status as independent contractors. In this debate, we want to provide sufficient knowledge to the Berkeley community regarding the sustainability of the gig economy, its effects on our present workforce, and the advantages and disadvantages of its operation. In order to present a diversity of viewpoints that illuminate this nuanced topic, we will have speakers that either affirm the viability of the gig economy for individuals or negate the possibility that it can continue without long-term negative effects.',
		Gig,
		'https://fb.me/e/2L2DndGRp',
		''
	]
];
