import AparnaIyer from '../../../img/bios/Aparna_Iyer_S21.png';
import DavidLee from '../../../img/bios/David_Lee.png';
import NoahOppenheimer from '../../../img/bios/Noah_Oppenheimer.png';
import OmotaraOloye from '../../../img/bios/Abhay_Aggarwal.png';
import OriannaJia from '../../../img/bios/Orianna_Jia.png';
import SadiaHasan from '../../../img/bios/Sadia_Hasan.png';
import LucyCheng from '../../../img/bios/Lucy_Cheng.png';

import AnhTuLu from '../../../img/bios/Anh_Tu.png';
import DustinNguyen from '../../../img/bios/Dustin_Nguyen.png';
import CrystalKim from '../../../img/bios/Crystal_Kim.png';

import AbhayAggarwal from '../../../img/bios/Abhay_Aggarwal_S21.png';
import AmitCohen from '../../../img/bios/Amit_Cohen.png';
import AnikaSinghania from '../../../img/bios/Anika_Singhania.png';
import AnkitaInamdar from '../../../img/bios/Ankita_Inamdar.png';
import BrianXi from '../../../img/bios/Brian_Xi.png';
import BryanKim from '../../../img/bios/Bryan_Kim.png';
import CaseyLei from '../../../img/bios/Casey_Lei.png';
import ChristieMaly from '../../../img/bios/Christie_Maly.png';
import DanielVoskoboynik from '../../../img/bios/Daniel_Voskoboynik_S21.png';
import JocelynGao from '../../../img/bios/Jocelyn_Gao.png';
import JonathanMin from '../../../img/bios/Jonathan_Min.png';
import KevinLiu from '../../../img/bios/Kevin_Liu.png';
import LizEddy from '../../../img/bios/Liz_Eddy.png';
import NayanChavan from '../../../img/bios/Nayan_Chavan.png';
import RhitishahRaju from '../../../img/bios/Rhitishah_Raju.png';
import SamyCherfaoui from '../../../img/bios/Samy_Cherfaoui.png';

export const execData = [
	[
		'Aparna Iyer',
		'President',
		'Aparna is a third-year studying Political Science and Human Rights. ' +
		'She joined the Forum in her freshman year and has loved the process of inviting speakers,  ' +
		'moderating talks with fascinating people, and getting to know the incredible members in the organization.  ' +
		'She is personally passionate about education and housing policy and always loves a good Twitter debate.  ' +
		'In her free time, she enjoys quality comedy television, dancing with friends, and visiting local coffee shops. ' +
		'Some of her favorite past Forum speakers have been SNL Director Don Roy King and author Gene Luen Yang.',
		AparnaIyer,
	],
	[
		'David Lee',
		'VP of Events',
		'David is a fourth- year student majoring in Economics and minoring in Data Science. He is  ' +
		'currently in the tech and events committees. In his free time, he loves to shoot photos of  ' +
		'his friends, read about the consumer electronics industry, and watch soccer and hockey.  ' +
		'Unfortunately, he supports Manchester United and the San Jose Sharks, the two most infuriating  ' +
		'sports teams on the planet. ',
		DavidLee,
	],
	[
		'Noah Oppenheimer',
		'VP of Finance',
		'Noah is a sophomore from Folsom, California (#916Proud) and intends to major in business  ' +
		'administration and data science. He is an avid sports fan and a member of the Debate Society  ' +
		'of Berkeley.',
		NoahOppenheimer,
	],
	[
		'Omotara Oloye',
		'VP of Moderating',
		'',
		OmotaraOloye,
	],
	[
		'Orianna Jia',
		'VP of Programming',
		'',
		OriannaJia,
	],
	[
		'Sadia Hasan',
		'VP of Communications',
		"Sadia is a fourth-year studying Media Studies and Public Policy. She has a passion for marketing, branding, and design, all of which she aims to use to help create greater awareness and engagement for Forum's events. As VP of Communications, she works with members to help design graphics and promote the Forum's events through diverse means. In her free time, Sadia enjoys baking, traveling, writing, and drawing. ",
		SadiaHasan,
	],
	[
		'Lucy Cheng',
		'VP of Systems & Technology',
		"Lucy is a sophomore from Florida studying Business and Data Science! She joined the Forum in Spring 2020 and has been a part of both the technology and moderating committee. Outside of the Forum she enjoys trying celebrity recipes, editing videos, watching TikToks, dancing to Ariana Grande, and drinking iced coffee. ",
		LucyCheng,
	],
];

export const directorData = [
	[
		'Crystal Kim',
		'Director of Internal Affairs',
		'Crystal is a second-year studying Data Science and Economics. She is currently Director of Social Affairs and is a member of the Finance and Systems & Technology Committees. In her free time, she likes to experiment with new recipes, creates extremely specific Spotify playlists, and binge listens to podcasts.',
		CrystalKim,
	],
	[
		'Dustin Nguyen',
		'Director of Development',
		'Dustin is a second-year studying Economics and Asian American Studies from Irvine, CA. This semester, he is the Director of Development. He loves TikTok, yoga, and online shopping. In addition to the Berkeley Forum, Dustin is also involved in imagiCal and the Haas Business School Association (HBSA). ',
		DustinNguyen,
	],
	[
		'Anh-Tu Lu', 
		'Director of Membership', 
		'', 
		AnhTuLu],
];

export const memberData = [
	[
		'Abhay Aggarwal',
		'Technology',
		"Abhay is a junior from Fremont, California studying Computer Science and City Planning. Outside of the Forum he does research with the Division of Computing, Data Science, and Society. He is a self-identifying NUMTOT and loves to talk about all things urban design and public transportation. He also enjoys k-pop, running, and watching The Crown. Abhay joined the Forum in his freshman year, and this will be his sixth semester in the organization (and first on Technology). Abhay has previously been on the Moderating Committee, where he was the VP of Moderating.",
		AbhayAggarwal,
	],
	[
		'Amit Cohen', 
		'Events and Communications', 
		'Amit is a second-year from St. Louis, Missouri majoring in Urban Studies and Statistics. He is a member of the Events and Communications Committees. In his free time, he enjoys watching his hometown St. Louis Cardinals and Blues, reading about the history of cities, and attempting to cook. He can often be found playing Spikeball on campus.', 
		AmitCohen
	],
	[
		'Ankita Inamdar',
		'Moderating and Events',
		'Ankita Inamdar is a third-year studying Business Administration with a minor in Data Science. Ankita joined the Forum as a freshman because she wanted to contribute to its mission of bringing accessible discourse to the Berkeley Forum and she has been heavily involved ever since. In her free time, Ankita enjoys curating Spotify playlists, indulging her sweet tooth, and enjoying the great outdoors.',
		AnkitaInamdar,
	],
	[
		'Anika Singhania',
		'Technology',
		'Anika is a sophomore studying computer science. As a technology committee member, she helps keep the website updated. If she is not coding, you can find her playing guitar, playing card games, or trying new restaurants. ',
		AnikaSinghania,
	],
	[
		'Brian Xi', 
		'Events and Communications', 
		'Brian is a first-year from Arizona studying environmental economics. He likes reading about art more than he likes doing art, riding public transit, and reading cool articles. ', 
		BrianXi
	],
	[
		'Bryan Kim', 
		'Technology and Events', 
		"Bryan (he/him) is a second-year Materials Science & Engineering major with intended minors in EECS and English. Having almost transferred out of Berkeley after his first year, he discovered the Forum to be an amazing organization where he can explore intersections between various fields and backgrounds that induce thought-provoking conversations. Outside the Forum, Bryan researches nanomaterials for next-generation electronic devices & energy conversion/storage, tutors math at the SLC, plays taiko drums with Cal Raijin Taiko, reads energy policy journals, and relentlessly spams the cowboy emoji everywhere he goes.", 
		BryanKim
	],
	[
		'Casey Lei', 
		'Moderating, Events, and Technology', 
		'Casey is a first-year from the Bay Area studying statistics and computer science. As the software manager, she oversees website updates and works alongside the VP of Tech. She is a big fan of train rides and mocha tesoras, and is always seeking book recommendations.', 
		CaseyLei
	],
	[
		'Christie Maly', 
		'Moderating and Programming', 
		"Christie is a second-year from Milpitas, California studying economics with a minor in data science. She joined the Forum in the spring of her freshman year, and has greatly enjoyed her time in the organization so far. She has a deep passion for Avatar the Last Airbender, Taco Bell, and League of Legends music. Outside of the Forum, you can catch her saying hi to the cows that live near her house, reading mediocre YA books, and watching the PBS NewsHour. ", 
		ChristieMaly
	],
	[
		'Daniel Voskoboynik', 
		'Events', 
		'', 
		DanielVoskoboynik
	],
	[
		'Jocelyn Gao', 
		'Moderating', 
		"Jocelyn is a first-year from San Ramon, CA studying Business Administration and Political Economy. She's passionate about international relations, social justice, moderating, the Bay Area, matcha lattes, lofi hip hop study beats, and melatonin. ", 
		JocelynGao
	],
	[
		'Jonathan Min',
		'Moderating',
		'Jonathan is a second- year undergraduate majoring in Economics and Political Science with a minor in History. He is passionate about Constitutional law, American/Classical history, and political philosophy. In his free time, he likes to play Spikeball, get some food, read, and play League of Legends. In the long run, he aspires to delve into academia and research after earning his Law Degree. ',
		JonathanMin,
	],
	[
		'Kevin Liu',
		'Technology',
		'Kevin is a third year from Los Angeles studying computer science and data science. He enjoys traveling to new places, meeting people, and catching up on the latest MasterClass.',
		KevinLiu,
	],
	[
		'Liz Eddy',
		'Finance',
		'Liz is a third year at Cal studying Economics and Political Economy. In addition to being involved in The Forum, she is part of Cal Mock Trial, serves as the Director of Scholarship for Delta Gamma, and enjoys exercising.',
		LizEddy,
	],
	[
		'Nayan Chavan',
		'Finance',
		'Nayan is a third- year from Nashville studying Data Science with Linguistics and Computer Science. She loves the British Royal Family, Google Sheets, and creating Slack reacts for the Forum. Her biggest fears include unemployment, ghosts, and regret.',
		NayanChavan,
	],
	[
		'Rhitishah Yuva Raju',
		'Events and Programming',
		"Rhitishah is a freshman studying Molecular and Cell Biology who is passionate about social impact in the health and tech realms. She enjoys driving with no destination, hiking, and is always down for a food adventure! ",
		RhitishahRaju,
	],
	[
		'Samy Cherfaoui',
		'Development',
		'Samy is a fourth-year.',
		SamyCherfaoui,
	],
	
];
