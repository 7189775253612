import React from 'react';

import '../css/Join.css';
import bigPic from '../img/join.png';
import Header from './A-WebElements/Header';
import Footer from './A-WebElements/Footer';
import Calendly from './Hooks/Calendly.js';

const JoinUs = () => {
	return (
		<div class="join">
			<section class="top">
				<Header name="Join Us" image={bigPic} />
			</section>
			<section class="bottom">
				<p>
					<h2 class="title">Spring 2021 Recruitment</h2>
					Our 
					<a href="https://docs.google.com/forms/d/e/1FAIpQLSckCCVVnCFOm3Sv0s-iLE69f5rj3CqlrkrbhtO7Inz-ta24MA/viewform">
							{' '}
							Spring 2021 Application
						</a>
						{' '}
					is live! Applications will be due on Friday, February 5th at 11:59 PM.
					Late applications will not be accepted. If you have any questions regarding our recruitment process, 
					please contact our Director of Membership, Anh-Tu Lu, at recruitment@forum.berkeley.edu.
				</p>
			</section>
			<div className="youtube">
				<iframe
					className="youtube-player"
					src="https://www.youtube.com/embed/fV2ufCKfkW4"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameborder="0"
					allowfullscreen="allowfullscreen"
					mozallowfullscreen="mozallowfullscreen"
					msallowfullscreen="msallowfullscreen"
					oallowfullscreen="oallowfullscreen"
					webkitallowfullscreen="webkitallowfullscreen"
					title="video"
				/>
			</div>
			
			<Footer />
		</div>
	);
};

export default JoinUs;
