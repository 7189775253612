import React from 'react';

import '../../css/Blog.scss';
import Header from '../A-WebElements/Header';
import Footer from '../A-WebElements/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const RandPaul = () => {
	return (
		<div className="conversations">
			<Header name="blog" />
			<Container fluid className="blog-content">
				<div className="header-homeland">
					<h2>
						Former Secretary of Homeland Security Jeh Johnson at the Berkeley
						Forum
					</h2>
				</div>

				<Row>
					<p className="italics">
						Former Secretary of Homeland Security Jeh Johnson appeared at the
						Berkeley Forum on September 15, 2015. Johnson’s talk centered around
						the evolving nature of threats to the homeland, while also
						addressing particular concerns regarding immigration enforcement and
						American security post-9/11. The event was moderated by Carter
						Keeling.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Carter Keeling:</strong>: You talked a lot about previous
						examples of interactions with demonstrators that you’ve had, many of
						which, have been successful. And I was personally, very surprised,
						that you went down, personally, to talk with those individuals
						outside. Of course, it wasn’t a very fruitful discussion. So, for
						individuals who are very impassioned, and very concerned, about the
						actions of your department, how do you, and how does your
						department, communicate a lot of the misinformation, and a lot of
						the numbers, if they’re so unwilling to listen?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Well, discussions like this, for
						example, on college campuses. I have spoken at the Heritage
						Foundation. I’ll go anyplace to have a conversation, and a dialogue.
						And what I’d say to young people, who feel impassioned about the
						issue, and want to make a lot of noise about the issue, I respect,
						and admire, and support your enthusiasm, and your commitment to the
						issues. Now, let’s channel that, in a way that can bring about
						change. Take my advice, on how to best put the pressure points on
						the system, to bring about change. Here are the people you need to
						talk to, here are the people you need to influence. I’m all for
						immigration reform. I’d like to see us deport fewer people, and
						focus more on the criminals, because that’s where our resources
						should be. Not just going after the people who are easiest to
						apprehend, but spending the time to look for the threats to public
						safety. That’s the smartest investment of our taxpayer money, and
						we’re not breaking up families. So, I’m all for immigration reform.
						I’m all for Congress passing comprehensive immigration reform, that
						provides for an earned path to citizenship, for those who are
						undocumented. Now, let’s talk to the people who are not, and see if
						we can change some minds. And there are ways in Washington, and in
						Sacramento, and elsewhere, to do that, in an effective way. And so I
						learned this from the repeal of Don’t Ask, Don’t Tell, in 2010. In
						January, 2010, when I was General Counsel of DoD, the Secretary of
						Defense, Bob Gates, asked me to do a study, on whether or not the
						military was ready to repeal Don’t Ask, Don’t Tell, along with an
						Army 4-star general. So, we spent 10 months doing this, and we made
						our report public. We surveyed 400,000 people, we visited military
						bases all around the country, in groups this size, we probably hit
						over 50 bases. Everyone we spoke to had an opinion about gays in the
						military. Everyone. This wasn’t an issue where people said, “Well, I
						don’t really know.” And so a lot of people in Congress… And it
						required repealing a law, from 1993. A lot of people in Congress
						were firmly in one camp or another, but there was that swing vote,
						there were people in the middle, who hadn’t made up their minds. And
						so that’s who I knew I had to target our report at, the people who
						wanted to listen, and the people who could be influenced, one way or
						another, by a thorough, exhaustive study. And we made our report
						public, on November 30, 2010, and the Congress repealed Don’t Ask,
						Don’t Tell, three weeks later. Frankly, it was an idea whose time
						had come. But with the advocates for repeal out there, working in
						the Congress, to influence the votes, that really didn’t make a
						difference, and it was smart policy change. And it would be great if
						we could marshall that kind of thing, because there’s a lot of
						energy out there, to marshall that kind of thing, when it comes to
						immigration reform.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Carter Keeling:</strong>: You mentioned, four days ago,
						9/11, was your birthday. But also on Friday was the 14th anniversary
						of the attack in New York City, and it was that national catastrophe
						that was really the impetus for the creation of your department.
						Now, in the roughly 13 years since its creation, do you feel that
						the DHS has succeeded in its founding mission, of making America a
						more secure place, that’s less vulnerable, less susceptible to
						terrorism, and other threats?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: I believe that our government, as a
						whole, through our counterterrorism efforts, through our law
						enforcement efforts, through our Homeland Security efforts, has made
						for a safer homeland. We do a good job, when it comes to detecting
						terrorist plots from overseas now, at their earliest stages, to the
						point where often, it’s a task of sorting out what’s real versus the
						noise, in the daily intel reports. But as I said, we’ve evolved to a
						new phase, that includes the potential for the lone actor, here in
						the homeland, who could act with little notice. And that presents a
						new and different kind of challenge, that we have to address. So,
						that’s a new phenomenon.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: Jeh Johnson, but failing our friends and
						our family, you do have the power to stop deportation, and you,
						specifically, released a memo, which targets our communities, that
						is not a priority. You want to the give right statistics, it is not
						95% of folks that are detained in detention centers. You detain
						domestic violence survivors that you’re supposed to protect. You say
						that we shouldn’t have reactionary…reactions to incidents, but a
						couple weeks ago, ICE did a huge raid in Southern California, of 240
						people, then that was under your jurisdiction. There is no
						accountability for any ICE agents, you continue to separate
						families. There is no accountability. You say you want to talk to
						community members. You have not responded to our memo, that we have
						asked, for clarity on what this priority enforcement program is. You
						continue to instill programs that criminalize our people. Why do you
						continue to do this, why do you continue to violate the Fourth
						Amendment rights of immigrants?
					</p>
				</Row>

				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Are you done?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I’m not done.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Is that your question?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I’m not done. I’m looking for an answer,
						and the whole time you have talked, you have not given anyone an
						answer.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Well, I’d be happy to answer your
						question when you’re done.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I’m here to listen to your answer.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Okay.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: Because we’ve been waiting for a long
						time.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Are you done?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: Yes. Go ahead.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: You asked a lot of different
						questions.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: And you haven’t answered any of them.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: You haven’t given me a chance yet.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I’m giving you a chance right now.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Okay. Would you like to remain
						standing, or would you like to sit down?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I would like to remain standing.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Okay. Are you going to interrupt me,
						while I try to answer your questions?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I’m listening.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Okay. Just please, promise not to
						interrupt me.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: I’m listening.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Okay. Number one, we’ve ended the
						Secure Communities program, which created a lot of legal and
						political controversy, because that program was rounding up people,
						based on mere warrants, and not convictions. It was also creating
						litigation in the courts, because local jurisdictions were holding
						people longer than they would otherwise do so, for purposes of
						immigration enforcement. And those jurisdictions were losing in the
						courts, so we’ve ended the Secure Communities Program.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: No. You have recreated it, through the
						PEP-COMM Program.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: I disagree. I disagree.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: No, you have. PEP-COMM has been
						installed to continue to run your deportation machine, and that is
						what the Department of Homeland Security is. And you profit off the
						detention of our people, and you continue to invest in institutions
						that profit off of the people that make this country. We have all
						once been immigrants, and you have not… You sit here, and you say
						that supposedly respond to the community, but you don’t. You have
						targeted every single undocumented [person], there’s no
						accountability for ICE agents at all.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Ma’am, if you believe that there are
						no threats to our Homeland Security, I suggest you take that up with
						the families…
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: May I speak?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: You did a constitutional waiver, don’t
						violate our constitution amendment, and give us that due process
						right.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: May I speak?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: No, I’m not going to stop –
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: You continue to deport all of our
						friends and our families. You continue to profit off of our people –
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: May I be allowed to speak, please.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: There’s no accountability.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Nobody else here gets a chance to ask
						questions, if you’re going to do this.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: You’re not going to give answers
						anyway…Eleven million people have been undocumented. We want answers
						and we want to hear them. We don’t want you to continue to work
						around the trust that we’re trying to build in our communities. We
						are trying to build trust, and you do not allow us to build that
						trust, with our local law enforcement, in our communities.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: You know what? Don’t do that. Folks,
						don’t do that. Don’t make her leave. Let her stay. Let her stay.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Protestor</strong>: Shut down ICE! Shut down ICE! Shut down
						ICE! Shut down ICE! Shut down ICE! Shut down ICE! Shut down ICE!
						Shut down ICE! Shut down ICE! Shut down ICE! Shut down ICE! Shut
						down ICE! Shut down ICE! Shut down ICE! Shut down ICE! Shut down
						ICE! Shut down ICE! Shut down ICE! Shut down ICE! Shut down ICE!
						Shut down ICE! Shut down ICE! Shut down ICE! Shut down ICE! Shut
						down ICE! Shut down ICE!
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member</strong>: Check, check. There we go. So, I
						just want to say hi, Secretary Johnson…. I just want to thank you
						for coming to Berkeley. It’s fantastic having you here. Glad you’re
						getting to see the passionate student body we have here. So, the
						question I had originally planned to ask you, you’ve already touched
						upon pretty well. It was a question about the specific strategies
						that your department’s using to prevent lone wolf terrorism,
						especially after the Chattanooga shootings. So, I guess I’d just
						like to ask you if you could go over some more of the specific
						strategies, if you’re at liberty to, and also leading one of the
						federal entities that’s been given specific abilities under the
						Patriot Act.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Well, let me give you two specific
						examples, of what we can and should do, and what we are doing, in
						the current environment. Number one, in July, I announced security
						enhancements, to what is referred to as our visa waiver program.
						There are 38… Can everybody hear me? There are 38 countries, for
						which we do not require a visa, to come here to this country. We
						have added security assurances, that we expect to get from those
						countries, with respect to people who travel here. So, we want those
						countries to participate to a greater degree in the Interpol Stolen
						Passport database. Put passports into Interpol, report them when
						they’re stolen. We want more flights that include federal air
						marshals from these countries. We want more sharing, we want them to
						develop, and use advanced passenger information, so that they know
						who are getting on flights, in their countries, like we do in this
						country. So, greater security assurances, with respect to countries
						from which people travel without a visa, to deal, frankly, with the
						phenomenon of foreign fighters. All these people who are leaving
						countries, and on occasion, this country, to go to Iraq and Syria to
						take the fight. So, that’s one thing. I mentioned before, countering
						violent extremism. So, one of the things that we can and should do,
						and we are doing, is encouraging communities to prevent and
						interdict, before it becomes a matter of law enforcement. A lot of
						people that the FBI arrest are very young people, younger than you.
						And so we want to encourage work with communities, give them support
						where necessary, to intervene, and interdict, before somebody
						decides they want to pick up, and get on a flight, and travel to
						Syria. So, those are two examples. One of the things that I also
						want to do, and that we are doing, we’re building more of, is
						something called pre-clearance capability, overseas. If you get on a
						flight from Amsterdam, to the United States, for example, you’ll see
						the customs capability, once we make this arrangement, at the front
						end, not the back end. Passengers actually like it, because when you
						get off that 8-hour plane ride, the first thing you want to do, is
						hop in the Uber, or the taxi. You don’t want to sit around, in a
						long line, waiting to be asked a lot of questions, by my customs
						officers. Do it on the front end, when you’re fresh, and we know
						more about somebody, before they get on the airplane. So, that’s the
						type of direction we’re moving in. We’re also more sophisticated,
						and better at connecting the dots, among our interagency, and we
						want to do the same, with our good counterterrorism partners
						overseas, with respect to individuals of suspicion. Okay. Next
						question.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member</strong>: Hi, Secretary Johnson. And my
						question is in response to the refugee crisis in Europe and the
						Middle East, Germany has promised entrance of 800,000 refugees,
						Venezuela, 20,000 refugees, and only a couple days ago, did Obama
						promise 10,000 refugees could make their home here. And I’m
						wondering why has there been such hesitancy, and what are you and
						your department going to do to speed up the process, for the
						refugees coming here, so they don’t have to wait two years in limbo,
						going through the process of entering a country.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: A couple of things. First, in addition
						to accepting 10,000 Syrian refugees, the United States government
						already provides billions of dollars in humanitarian assistance, to
						the refugee problem right now, something like 4 billion. We’re the
						biggest single donor to that effort overseas. So, that’s one thing.
						We also have… We’ve got Central America, and we’ve got Mexico. So,
						as I’ve mentioned before, something like 330,000 apprehensions on
						our Southern border, many of whom will apply for asylum in this
						country. I have a finite number of CIS personnel, that can be
						devoted to screening for asylum, and screening for refugee status.
						And when you do screening for refugees, they have to go overseas, to
						places like Iraq, or Jordon, or other places, in border countries.
						They have to go overseas to do this screening, which means diverting
						them away from a lot of other immigration services. The other thing
						about CIS, United States Citizenship and Immigration Services, which
						is responsible for refugee screening, it is a fee-based
						organization. It pays for itself through fees, applications,
						application fees, for green cards, and so forth. So, it’s got to pay
						for itself, and refugees is not a fee-based activity. So, you’ve got
						to pay for the refugee screening we do, through fees coming from
						other types of services. So, we want to do more, we will do more, by
						way of refugees, by way of direct humanitarian assistance, with our
						dollars, and we want to do it as quickly as possibly, but like
						everything in government, you’ve got to do it with the resources
						that you have. Right now, as I said, Congress has yet to pass a
						budget for 2016, and unless they affirmatively repeal it,
						sequestration… Everybody knows what sequestration is, right?
						Sequestration is going to kick back in at the beginning of next
						calendar year, which decapitates my budget. So, lots of times,
						people want us to do more, but you’ve got to give me more to do it
						with. And I can’t do more with less. So, if we have to live with
						sequestration, we have to cut back on immigration services, border
						security, law enforcement, counterterrorism, aviation security,
						cybersecurity, Coast Guard, maritime security. We’re still feeling
						the effects from sequestration, two years ago. So, that is my plea
						to this room. Support repeal of sequestration, so we can do more, of
						the types of things, that folks here on this campus want us to do. I
						have time for one more question. Yes, sir. Right here.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member</strong>: Ah, yes. So, you’ve spoken a lot
						about counterterrorism, and the rising threat of lone wolves, and
						similar effects that are totally unexpected, and so understandably
						you’ve… I mean, I’m not privy to the surveillance methods, or
						different mechanism that you try and anticipate such attacks, or try
						and prevent them before they occur, but are there mechanisms that
						you have in place, to ensure that you’re not overstepping, so as to
						infringe upon civil liberties?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Jeh Johnson</strong>: Yes. Yes. Unlike the Department of
						Defense, where I used to work, the Department of Homeland Security
						has an Office of Civil Rights and Civil Liberties, and an Office of
						Privacy, that reports directly to me, that is privy to the most
						sensitive Homeland Security programs we have, that provides exactly
						that type of balance. And I hear from them directly. They sit in on
						all the most sensitive meetings, to ensure that we’re not crossing
						those lines, that we’re not profiling people by race, religion, skin
						color, and the like, that we’re not crossing the lines, in terms of
						inhibiting travel, and so forth. And that’s something that I
						personally believe we absolutely, we have to do. Actually, I do want
						to say one more thing. To the students here. When I was in college,
						I wanted to be in public service, politics. I wanted to be in public
						service, and I never lost that dream. I went to law school, I got
						sucked into the Wall Street corporate law thing for a while, but I
						never lost the dream of public service. And so I came back to it,
						I’ve been four times in public service. As a federal prosecutor, as
						General Counsel of the Air Force, General Counsel of DoD, and now
						this job, which I never imagined I would be in. And it’s been great
						every moment, there is never a dull day in my job. And I have, in
						public service, way more to talk about at parties, than I did at my
						private corporate law practice. And the basic instinct of all of us
						is to serve, and to help people, which is what you do most often in
						public service. So, those of you students who are thinking about a
						career in public service, at the state, local, or federal level,
						don’t lose that dream. I hope it stays with you. Even with the
						mortgage, with the kids, the dog, the tuitions, the student debt.
						Don’t lose that dream. I haven’t. I made a lot more money in
						corporate law. Right now, I make less than probably a third year
						associate, three years out of law school, at my law firm in New
						York, Paul, Weiss, Rifkind, Wharton, and Garrison. I run an
						organization of 240,000 people. I make less than a third year
						associate, but I would not exchange it for anything in the world.
						So, thank you all very, very much.
					</p>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default RandPaul;
