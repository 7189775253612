import React from 'react';

import '../../css/Blog.scss';
import Header from '../A-WebElements/Header';
import Footer from '../A-WebElements/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const RandPaul = () => {
	return (
		<div className="conversations">
			<Header name="blog" />
			<Container fluid className="blog-content">
				<div className="header-ellen">
					<h2>
						Chief Diversity Officer for the Kapor Center Ellen Pao at the
						Berkeley Forum
					</h2>
				</div>

				<Row>
					<p className="italics">
						Ellen Pao appeared at the Berkeley Forum on September 28, 2017. Ms.
						Pao’s address focused on her personal experiences working in Silicon
						Valley, along with her hopes for diversity and inclusion in tech and
						beyond. The event was moderated by Shaina Zuber.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> We’re so pleased that you’ve made
						your return here with us tonight. In your book you chronicle your
						life from growing up the child of immigrants, to your time at
						university, studying everything from engineering to law and
						business, to your time in Silicon Valley working with a number of
						startups to eventually your suit against Kleiner Perkins. Throughout
						this journey, at what point did you come to believe that the system
						you are entering had been built in a way that limited your ability
						to succeed?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> For a long time, I thought it was either
						me or an individual. So, if there was an all-male steak dinner at
						the law firm that I was at, I thought, “Well, that guy who organized
						it, what a jerk,” and I’ll just go and organize my own dinner with
						my friends and we would have our dinner. But you know I didn’t
						realize until much, much later and I was out of the field of law
						that actually, those dinners with partners, are different than
						dinners with peers, right. They’re networking opportunities, they’re
						opportunities to get visibility. They’re opportunities to be seen so
						that when the good opportunities come up, you’re considered, you’re
						in the mix, you’re known. So, it wasn’t as a lawyer, it was much
						later. It wasn’t until I was at Kleiner. So, throughout my career,
						there’d be little things that I would notice but brush off or I
						would just work harder, but I would continue to succeed. So, I was
						lucky enough to be able to continue to move up in every job that I
						had and it wasn’t until I was at Kleiner Perkins when I hit a wall.
						But really, the thing that had me understand how systemic it was,
						was seeing all the other women hit the same wall. So, there was one
						promotion time where none of the women got promoted and almost all
						the men got promoted. We had…the women had better education, more
						work experience before joining Kleiner, more years at Kleiner on
						average, overall. One of the women had actually done this detailed
						analysis investment by investment, partner by partner, to show that
						the woman’s investments were actually doing much better than the
						men’s investments. And yet, despite the education, the experience on
						different levels and the better investments, none of the women got
						promoted, most of the men got promoted and that was a clear signal
						to me that something was wrong.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Did you feel like the other people at
						the firm were cognizant of this disparity and ignored it or simply
						didn’t even notice?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think there were people who noticed it
						and were like, “Just work through.” There was one person who advised
						me, like, “Forget about the dinners but this board seat that they
						took you off of, you should really be fighting for that.” So, pick
						your battles basically. I think the men were not cognizant. I think
						there were certain small things that I think some of them were
						cognizant of but it wasn’t big enough for them to do anything and it
						wasn’t something that they saw as systemic.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> While you were working at Kleiner
						Perkins in venture capital you were essentially seated at the
						intersection of the tech world and high finance, both of which are
						industries which have been criticized for the treatment of female
						employees there. How did you feel these two industries were
						different and similar in how women were treated and potential
						discrimination they faced?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> It’s hard to say because I worked in a
						tech field at a different time. Like I worked in tech from 1998 to
						2005 and that was when it was still the nerds who were working. It
						was very much like people who were engineers, they were interested
						in building product or technologies and it was much geekier. In
						2008, it changed quite a bit because Wall Street all of a sudden,
						became toxic or the toxicity was more public and people didn’t want
						to go to Wall Street anymore. It was a time where the banks were
						starting to fail and people coming out of business school like Haas,
						decided I’d rather go to tech where I can see that these guys from
						Google are now billionaires and they built their companies in 7 or 8
						years and became billionaires with public offerings. Mark Zuckerberg
						was starting to come up and there was this idea that the way to make
						money is to go to Silicon Valley and no longer to go to Wall Street
						and that changed the culture. So, I think the culture got much more
						toxic after 2008. So, it’s different than when I was there. If I
						look at venture, I think the big difference between venture and all
						of tech is that it’s such a small group of people, it’s such a
						concentration of power. There are the relationships, there are the
						people who can make companies and they’re king makers. They pick a
						person and they invest in them and they put money into them and it
						doesn’t matter what they do, they are able to get repeated chances.
						You look at the guy from Zenefits. He was fired for fraud and
						building software to get around insurance regulations and yet he
						started a new company and raised several million dollars from the
						same investors who had just barred him a year or so ago. So, you get
						multiple chances if you’re in that in-group and there are just a few
						people who can make or break your company and that drives into the
						companies that they invest in and sets the tone for the rest of the
						industry. So, that concentration of power and the influence is
						incredibly strong.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> As you mentioned, venture does have a
						large impact on what companies succeed and which ones fail. In 2016,
						companies run by men received 16 times as much funding from venture
						capital firms as those run by women. What do you think drives this
						disparity and capital allocation and what do you believe should be
						done to address it?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> So, they call it pattern matching and I
						think in that speech that I was here for, like 7 or 10 years ago, I
						think there was probably talk about what makes for a successful
						company. One of the things that that my firm used to talk about is
						white male nerds who dropped out of school. So, they looked at the
						pattern of the guys from Yahoo, the guys from Google, Mark
						Zuckerberg and the success factors that they decided were most
						important, were white, male, nerd and that ended up being the people
						that they invested in. As time went on, those ended up being the
						people that were successful because they were the only people who
						got investment. So, you end up with this self-fulfilling prophecy
						which all of a sudden, looks like the numbers that you shared. Like
						more and more investment goes into this small group of people and
						others are shut out of the whole process.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Do you think there are any trends
						towards improvement in this or has it been pretty static since
						you’ve been in Silicon Valley?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think the latest numbers have gotten
						worse. So, it’s been pretty static. I’m encouraged though, by the
						fact that now there are some small funds that are run by women, that
						are run by underrepresented people of color. I think there is more
						awareness of the problem and I think there are people who are
						recognizing that. There are women and people of color who are a huge
						opportunity to invest in because others are not looking at those
						sectors. So, there are all these awesome entrepreneurs that are
						getting shut down or rejected by the mainstream investors that have
						great ideas, that have great teams, that have great opportunities
						and the ability to invest in them without having to fight off 10
						other venture capitalists is an incredible opportunity.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> You mentioned in your book that while
						you were at Kleiner Perkins working in this sort of environment, you
						really stressed this issue of maternity leave and how taking
						maternity leave could have detrimental effects on people’s careers,
						and people could feel forced into working when they were on
						maternity leave. How do you think this issue should be addressed
						either through policy solutions or a change in culture or both?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think it’s a mix. So, when I was at a
						law firm in 1994, 1995, there was one woman who was there who had
						been there for a long time. She started in law before women could
						actually go to law school. So, women would have to apprentice. So,
						she apprenticed with a lawyer in order to be eligible to pass the
						bar and work as a lawyer. She said to me, like, “If society values
						people raising their kids, they need to make it possible for people
						to work and raise their kids at the same time. So, if it values
						women being involved in raising kids, then women should be given
						ways to be able to raise their kids while still working.” If you
						look at companies now, they’re looking at parental leave instead of
						maternity leave and I think that helps a lot. If you make it so that
						it’s something that both genders or all the genders experience
						equally, then people can be more empathetic. “Right, look, I want to
						take this time off but I’m feeling all this pressure not to because
						people don’t think that paternity leave really makes sense, maybe
						I’ll feel more empathetic to the person who’s trying to take leave
						as well.” I think it’s a mental shift in trying to understand, like
						do we value people raising their kids, do we value people working,
						do we want them back in the workforce, do we want to include
						everyone in these opportunities and make sure that we have everybody
						participating to the full extent of their capabilities?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> You mentioned in your book that you
						did try to implement something of a cultural shift during your time
						as interim CEO at Reddit, and one of the policies you implemented
						was to ask potential employees questions to discern their views on
						diversity. How would you respond to those that charge that this
						practice could stifle dissent or violate the ideals of free speech?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> Nobody actually said that to me. I think
						they were… Like at that point, people knew I had sued and I think
						they were wiser than to say something that would be that. I think it
						was before, it was pre-Google manifesto, people weren’t talking
						about those views as openly. But I did have one person who said that
						he believed in diversity and he was willing to lower the bar to
						bring in women and people of color. I was like, “Oh, that’s really
						interesting, let me just make sure I understand what you’re saying
						because I’m going to ding you and I don’t want it to be based on,
						like my mishearing” But there is this idea that people just are not
						equal and it’s very strong in certain people and they can be very
						earnest and they can really have the best of intentions but they
						have this fundamental belief that is really difficult to shake. Like
						that guy, his team was mostly white and mostly Asian. I was like,
						“Well, why do you think that is? And you can get to people’s beliefs
						indirectly without them stating it explicitly, but it’s hard to have
						a conversation once you get to that spot, right. Like where do you
						go?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Another policy instituted at Reddit
						was to remove salary negotiations as studies have shown that women
						tend to fare worse when salaries and bonuses are negotiated. What
						impact do you think this had on the business and do you think it
						should be replicated at other firms?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think the press was a little bit
						unclear about what it actually was and that was partly my not
						communicating it clearly. I think what we did was we gave everybody
						the highest negotiated salary we’d be willing to give. So, we had
						bands and we would put everybody at the top of their bands based on
						their experience and their skills and the job that we were hiring
						for. It worked out really well because I think there’s this tension
						between the candidate and the employer where they want to feel like
						they’ve negotiated well but they also don’t want to burn the
						relationship. And as the employer, you want to hire them but you’d
						also want to pay fairly across your whole team and we could see at
						Reddit, there were some managers who were more generous than other
						managers. You could say they were actually worse negotiators,
						depending on your perspective and then there were some employees who
						were really strong negotiators and they were just way out of band.
						So, you look at that and it’s completely unfair and for us to really
						stick to the bands, we wanted to make sure that we’re giving people
						the amount that we thought they were worth which would be at the
						high part of the band.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> And this was implemented across all
						levels of the company?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> Yes, I looked at every offer and I had a
						recruiter, Phil Haig, who…we put every offer together and we would
						go through the bands and we’d say, like, “How many years of
						experience do they have in this particular skill set?” So, it was a
						little bit time-consuming. We would have had to do it differently at
						scale, we were pretty small then but you felt better. Like when you
						gave the offer, you felt better and there were candidates that would
						say, “God, you know, this is a really generous offer. This is more
						than I would have asked for. I really feel good about it because I
						don’t feel like I have to negotiate for myself. I’m not comfortable
						negotiating for myself and I’m super excited to take the job.” And
						we would cut out like 1 or 2 weeks of awkwardness where you’d give
						out the offer and you wouldn’t hear anything and then you’re like,
						“Maybe we should have given more or what are they thinking?” And
						they would call back and they would want to negotiate but they
						already felt like it was a good offer, so they weren’t sure how much
						to negotiate.And there’s that whole awkward set of interactions
						where it’s a little bit of tension because you want more and the
						company wants to give you less, maybe, so you’re at odds, but this
						created this much more positive experience and also, we could close
						people faster. So, we could get them in the door and for us, we were
						growing really quickly and that ended up being a huge benefit that
						we hadn’t anticipated.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> Have you seen this being replicated
						in other firms across the industry?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> There are other companies that are doing
						it. So, we were…I don’t think we were the first, although we didn’t
						do it to copy other companies. It started with Yishan, my
						predecessor but he did it more informally and then I made it formal.
						I made sure that everybody followed it.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> On this issue of differences position
						and salary within an organization, you mentioned in your book that
						you felt you were really only able to file suit because you were in
						a financial position to do so. How do you feel the experience of
						women and executive roles who maybe are in this financial position,
						differs from those of women in potential lower levels who couldn’t
						take this sort of action you did?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think it’s not really level that it
						depends on. I think it depends on, like what are your other
						financial obligations, where you, you know, you could have family
						money, you could have a spouse that works, you could have saved a
						lot of money? So, I think it’s more, what is your specific
						situation, if you don’t have kids or if you don’t have a family to
						support elsewhere, if you don’t have other obligations, it’s
						different. Also, it depends on your appetite for risk. Everybody has
						a different appetite for how they think about taking chances and it
						really is not something where I’d say, I think all women who have
						the financial resources to do this should do this because it’s hard.
						It depends on your mindset, it depends on whether you’re willing to
						take that risk. It depends on how thick your skin is because it can
						be very ugly and it depends on how willing you are to be in that
						spotlight.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> In addition to these varying levels
						of financial stability, there’s also a great amount of diversity in
						terms of experience, in terms of race. Do you believe the experience
						of women…or how do you believe the experience of Asian American
						women in the tech industry, in the business world in general,
						differs from that of white women or of other people of color?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think you can look at studies. There’s
						a group Ascend, that is a nonprofit that is coming out with a new
						report, I think, in a few weeks and they’ve looked at race and
						gender to see how people get promoted and how people get hired.
						They’ve shown very specific differences. When you’re basically not a
						white male, the further away you are, the harder it is for you to
						get to the top and the harder it is for you to succeed and it
						differs by category. But I think it’s also hard to focus so much
						attention on different groups. I think it’s important to understand
						how race plays a factor into it but it’s often used to pit one group
						against another. So, it’s like, “Well, Asians shouldn’t be part of
						this conversation because at least they get hired or Latinx people
						shouldn’t be part of this conversation because they can assimilate
						better, right” So, there’s a lot of competition that ends up like,
						“Well, my situation is worse, so I should get more benefit or your
						situation is less bad, so you should make room for my group.” I
						think that’s really hard, when the focus should be on, like, there’s
						a systemic problem where people are not being included and we need
						to change the whole system so that everybody gets included fairly.
						So, it’s not, “I’m going to have one slot for a woman and then once
						I put that woman in, nobody else gets to be on that panel or in that
						executive suite. Or I’m going to put a slot for diversity and once I
						fill that with, whether it’s an Asian person or a black person or a
						Latinex person or somebody with a different sexual orientation, then
						I’m done.” That is kind of attitude when you start parsing through
						very closely, when people start talking about kind of trying to
						compare different groups. I think it should very much be like, who
						are the best people for these opportunities, who should we be
						listening to, who should we be getting perspectives from and that
						should be everybody. We can’t… You know, I see a lot of it, where
						like groups are starting to… Where this fragmentation is super toxic
						and really, we need to be working together to make sure that
						everybody gets a fair chance to succeed.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> For the sake of time, this is going
						to be my last question before we move on to audience questions. In
						your book, you discussed the need for diversity in terms of gender,
						race, sexual orientation. What do you believe people within the tech
						industry or outside the tech industry, who might not be part of
						these underrepresented groups should do to bolster this goal?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think everybody has a voice that can
						make a difference and I’ve seen throughout my experience… When I
						first filed suit, it was very lonely and it was very hard. People at
						my office were scared to talk to me. I was a social piranha. There
						was one who talked to me for a few months but we had to meet outside
						the office and she would leave 5 minutes before I left and we would
						meet way far away from the office because she did not want to be
						seen with me. So, that was a very lonely, hard time. People would
						move away when I sat down at a table. Like it was very kind of high
						schoolish but it was hard because we had been there for 7 years and
						these were the people that I had worked and spent so much time with.
						But people would email me and they would send me supportive messages
						and a few people said things publicly and then it grew over time and
						you saw other people speak about their own experiences. And it
						wasn’t just women or women of color, it was also men saying, “This
						happened to my mom or this happened to my coworker and she told me
						because she had read about your experiences. I believe you, even
						though the press is completely attacking you and saying that you’re
						not telling the truth, I believe you,” and that can be very
						powerful. So, you don’t have to go out and sue, you don’t have to go
						out and take huge steps but just speaking up for other people. When
						your classmate or your coworker is always asked to get the coffee or
						get the cookies or take the notes, you can speak up and say,
						“Actually, it seems like she’s done that several times. Why don’t I
						do it or why don’t we have it go through everybody and the whole
						team does it from time to time?” So that it’s not that person’s
						additional burden to speak up and fix the mistake. Everybody uses
						their voice and creates the change that needs to happen.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Hi, there, thanks for being here.
						So, this is a question around diversity efforts and the liability
						that they create in a company. I know that there is some information
						that is on the Project Include website about that but I’m interested
						in kind of talking a little bit about…there’s a kind of an explosion
						right now of diversity and inclusion.And I know that a lot of
						companies bring in consultants to kind of explore it internally and
						then provide recommendations. Can you talk a little bit about what
						you see as maybe some good and maybe some harm that that can cause
						in terms of how companies handle it, I think?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> Yes, I think our values at Project
						Include our inclusion, comprehensiveness and accountability. So, the
						first one, when we talk about inclusion, we mean inclusion of
						everyone. We see a lot of companies who are focused on women and at
						the end, that means white women usually. And it means that they’re
						opening up the boys’ club and adding a few people but the general
						culture is that some people are in the in-group and some people are
						not in the in-group and just because you add a few more people to
						the in-group, doesn’t mean you’re an inclusive company. So, thinking
						about it from the perspective of, how do I give everyone a fair
						chance to succeed? The second point, comprehensiveness, is tied to
						making sure that it’s everything in your company. So, it’s not just,
						let me hire a few people and throw them into the company and hope
						they swim. It’s really, like your processes for hiring, your
						processes for promoting, for paying people, for giving performance
						reviews, for giving opportunities, giving visibility, for giving
						sponsorship and mentorship, should be fair and it should be
						inclusive. And then the last piece is, you need to be accountable
						and you need to measure what’s going on in your company to make sure
						that you are seeing progress and that you’re taking steps that are
						actually working. One of the things that we’ve seen that there’s a
						lot of research on, is that when you have the consultant come in and
						do the 90-minute unconscious bias training, it can actually be
						hurtful to attitudes and hurtful to diversity and inclusion in your
						company. So, really thinking about, like what are all the steps and
						what are the ongoing things you’re going to do to make the company
						actually inclusive is really important.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Hi, there, my name is Olivia I’m a
						computer science student here. So, I often find myself surrounded by
						all men because, you know, project groups, classes, extracurriculars
						and more and more, the conversation of diversity does come up. But a
						lot of times, I’m the only diverse candidate or a female candidate
						etc. in those conversations. So, I guess I wanted to know like what
						your advice would be, because oftentimes those conversations can get
						a little bit tense or it’s kind of awkward. So, like what advice
						would you have to create meaningful and productive conversation?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> It’s a hard one because, like, it’s
						great to educate people but it’s exhausting, right. So, in every
						conversation, people turn to you because you’re the only person who
						doesn’t look like them and you’re expected to do the work of
						educating all of them. I think a big part of it is trying to get
						people to educate themselves. So, you can hopefully, tell them,
						like, “There’s so much information out there on the Internet, in
						medium, on Twitter, in all different magazines,” to push them to
						actually do some homework. Like, “Hey, that doesn’t seem like a very
						educated opinion, you should actually do some research, right. Go
						Google diversity and intersectionality and learn something.” But
						it’s hard because you’ll feel like you want to but I think you have
						to think about, like what are you willing to do, does it make sense
						for you to do? And also, are there some people that you just can’t
						change their minds, right. Like why put energy into that
						conversation if they’re really difficult and it’s exhausting. You
						let go and find somebody else to talk to you hopefully.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> I’m Wendy Yang. I’m currently a
						freshman engineer, mechanical engineer and I’m also an immigrant
						from China. So, this is something, like it’s not going to be related
						to your work but it’s more about Reddit. Do you…despite with what
						happened last year with Reddit, with r/The_Donald and r/pizzagate,
						do you still stand by your defense of not banning the subreddits
						despite with what happened last year?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> So, I actually banned 5 subreddits. So,
						I banned five subreddits that were harassing other users of Reddit
						and I personally would have banned The_Donald. I think they were
						harassing people, driving people off of a platform and it was
						something that has been really toxic and now the measures to try to
						fix it aren’t are kind of convoluted. Like if they had just stopped
						it in the early days, it would have been a lot easier. So, I think
						there’s a difference between stopping people for hate speech and
						stopping people for harassment and that was the line that I drew.
						It’s too hard to define hate speech. You have a whole law school
						here where the professors are going to debate it and, in a company,
						you’re dealing with millions of posts and a small team of people who
						are being paid by the hour. And expecting them to be able to manage
						all of the hate speech and get rid of it is hard, but expecting them
						to identify harassment, you know, people who are really pushing
						people off a platform or creating this toxic environment for
						individuals, is something that I think is doable.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Hi Ellen thank you so much for
						being here. My name is Divya and I’m a senior here at Haas. A
						question that I had for you has to go back to, I guess, unconscious
						bias and also the role of female ally-ship and sponsorship. I think
						you mentioned this before, that in the beginning of your fight or
						the beginning of you pushing towards this, it was often times even
						lonely from your, I guess, fellow sisters or even female mentors as
						well as co-workers. So, what recommendations do you have for either
						someone who’s receiving this and feels maybe alone in the fight but
						also, how we, not only as women but as Berkeley students as a whole,
						can provide more of a sponsorship or ally-ship with people going
						through this?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> It’s a good question because I hear from
						a lot of people, they’re like, “Oh, the advice I’ve been given is I
						need to go find a mentor, right. Let me go find a mentor, I don’t
						really know how to do it.” I was told to find a mentor and I asked a
						bunch of people and they didn’t want to be my mentor. So, it doesn’t
						feel very good. So, I think you realize that you actually do have
						mentors. So, if you think about, like who did you talk to you when
						you were trying to decide what to major in, who did you talk to you
						when you were trying to decide what school to go to. Those are your
						trusted advisors that you could call “mentors.” You kind of, as you
						move along, you’ll find more people who are helpful in that way and
						you can do the same for other people. You provide the advice based
						on your experiences and as you gain more experiences, as you enter
						the workforce, you’ll be able to share the same amount of…you’ll be
						able provide help to people and hopefully, they’ll be providing help
						to you and building that reciprocal relationship can be very
						powerful.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Hi, Ellen, my name is RJ. I’m a
						senior here at UC Berkeley studying computer science. So, I wanted
						to ask two questions. My first question is, in terms of, as you
						said, that currently diversity programs are kind of basically, like
						hiring a diversity or underrepresented minority and then putting
						them…once the quota has been filled, it’s kind of like ignore
						everyone else. So, currently, we see that this doesn’t really work,
						the gap hasn’t changed as well as also, there’s been recent backlash
						over this policy. So, how would you suggest, like do you think
						there’s anything you would improve on that policy or like how would
						you go about it?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> Yes, I think the big problem is like you
						throw people into an environment and expect them to succeed when it
						has been built on decades of exclusion. It’s very hard. Like if you
						look at the way companies are operated, especially the bigger
						companies. They follow this path where they hire their friends.
						Venture started, I think, maybe 40 or 50 years ago and it was a
						group of eight guys who came out of the semiconductor industry and
						they started funding companies and they funded people who looked
						like them, who were their friends and then they funded their
						friends. It just became this very kind of culture fit based system.
						And as things continued, like you built systems that were oriented
						around, “How do I hire people who look like my friends and look like
						me?” So, all the hiring processes, all the promotion processes… You
						know, you look at the 360-review process, it’s all oriented towards
						keeping a certain set of people, the traditional establishment
						successful. So, it’s really kind of taking apart all of those things
						and maybe bringing in technology. There are a bunch of great
						technology companies that are designed to root out bias in hiring
						and managing, in performance reviews, in job descriptions. So, there
						are a lot of tools that you can use but it’s like the mindset of, “I
						know there’s a problem and I’m going to fix it.” I think for
						students coming out of school now, it’s like, where do I find those
						companies that are better than others? I don’t think there’s a
						perfect company. I would love to say, “These are the five companies
						you should go work for.” I think that we have a long way to go
						before any of these companies have leadership and engineering teams
						and overall representation that looks like the workforce, you know,
						demographics of the workforce in their company workforces, but there
						are companies that are better than others and you can tell by
						looking at their website. You can tell by looking at their
						leadership teams, their boards. I would use that as my guide to try
						to find the companies that are doing better than others.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> And my second question, if I can,
						is regarding…so, recently, we had our free speech week here at
						Berkeley and given your experience with the incident, I’ve read it.
						So, would you say the response by Christ was appropriate or how
						would you might have responded if you were in her position?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> We were talking about this backstage. It
						is such a hard, complicated issue. I’ve not looked deeply into
						Berkeley’s experience but I will tell you that as someone who… I was
						this college reporter on the school newspaper and I was super
						pro-free speech. It was like this great way of getting out ideas, of
						being very open and transparent about things that were happening and
						as I’ve seen the internet evolve, it has made free speech into a
						weapon against certain people who disenfranchised. There was a time
						at reddit where we had these nude unauthorized pictures of various
						stars and celebrities and we ended up, like being the site for
						finding those nude pictures and it drowned out all other
						conversations because so many people were walking to the site to
						take a look at these nude pictures. We couldn’t keep up the
						conversation. So, we ended up becoming the site for nude pictures.
						If you think about what happens when you allow these really loud
						voices who are so toxic and driving off all the other people on your
						site, you don’t have conversations. You have like the loudest most
						visible group taking over the whole site and you have conversations
						and you don’t have a place where people feel like they can
						participate. I think it’s hard because it’s a public institution and
						because it’s had this commitment to free speech from the start, but
						I think the world has changed and evolved and there’s a big part of
						a community and a platform that involves making sure people are free
						from harassment, that people have the opportunity to speak and that
						at a college, that one of your values is inclusion, of making sure
						that everybody feels safe to exist and to be able to participate. I
						don’t think that happens when you have voices that are telling you
						that, you don’t belong, that you’re not equal and that you should
						not be given opportunities thank you.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Hi, my name is Terrell, I’m a
						senior studying computer science and philosophy. I have a little
						story to tell. This summer while I was interning at big tech
						company, I kept noticing these things you were talking about such as
						superficial diversity measures, tokenism. I ended up writing a
						letter to the Chief Diversity Inclusion Officer of that company and
						the letter, I also posted on the intern group and it was spread and
						somehow leaked from the intern group and many full-time engineers
						also saw it and they kind of circulated it around the company. Time
						and time again, the backlash… There was a lot of backlash but the
						most prominent backlash I got was that I was being offended for
						someone else. That I, as a Asian male in tech is relatively
						privileged and that for those reasons, I shouldn’t be the person
						who’s saying these kinds of things. So, for someone like me, who’s
						relatively privileged or even a white male in tech, how do you
						advise someone like me who still wants to be in the conversation,
						like navigate this?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> So, it’s hard for me to understand
						exactly what happened. It sounds like you wrote a letter and people
						shared. It got distributed pretty broadly and then you got some
						backlash because you’re coming from a place of Asian privilege and
						it wasn’t your place to talk about the problems. It sounds like the
						problems of exclusion at the company that was happening to
						potentially…
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> To tokenism, superficial…
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> To tokenism, yes.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> There were some measures and, yes.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> Yes, I think it’s really hard to go up
						against the system and people will use what they can to keep you
						from speaking out about the problems that are systemic. That is part
						of what happens in tech to women and people of color and older
						people and people who have disabilities and people who have
						different sexual orientations. Like, that is what happens. There is
						a traditional establishment view and when you have a counter view,
						people try to squash it and that… You know, so, I think you have to
						be prepared for it. It’s not pretty but that is like the start of
						change. So, in some people’s minds, they probably agree with you but
						it’s hard to do that publicly at this point but maybe there’ll be
						somebody next summer who says the same thing, and maybe there’ll be
						a few people then and maybe there’ll be some people from the team
						who then join in. But being the first to call out problems is often
						not… You don’t feel like you’re making a difference but you do. Like
						it takes a village to change things and that means many different
						people speaking up at different times.So, I think you should be
						really…like it’s brave and you should be proud of yourself for
						having done that and I don’t think you should look at it as, “I was
						a failure,” or internalize the criticisms. It’s very much, you know,
						you tried to do the right thing and even if it didn’t work, I think
						you can feel good about yourself having said it rather than seeing
						more and more problems and you didn’t try to do something.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Shaina Zuber:</strong> I think for the sake of time, we have
						time for one more audience question.
					</p>
				</Row>
				<Row>
					<p>
						<strong>Audience Member:</strong> Oh, good. I feel very lucky.
						[Laughter] That’s a lot of pressure. So, thank you so much for
						coming. I am work in VC and have definitely been asked to bring
						coffee from entrepreneurs, but my question is we hear these things
						about how important diverse opinions are in a VC setting. I was
						wondering if you can tell us some tangible things that you have
						experienced by having a female investor in the room, in your partner
						meetings, in the discussions that you have. And what has…what has
						having like all white male VC’s gotten us so far? I mean, what are
						some problems because of that?
					</p>
				</Row>
				<Row>
					<p>
						<strong>Ellen Pao:</strong> I think you see… I think one of the most
						impressive things about the tech sector has been this massive
						creation of wealth and power in a short amount of time. And what
						you’ve seen with the white male VCs, is that it stays in that group
						and all of these other groups are excluded from this creation of
						wealth, this creation of power, this ability to donate to create
						opportunities for people who haven’t been able to participate. This
						ability to hire people, this ability to share the wealth more
						broadly and to help communities and that’s a huge problem. I think
						by investing in these homogeneous groups, you end up with products
						like Nextdoor. Which is, you know, every time I go, there’s some
						racist comment about like, “I’ve seen this person from this
						demographic and I think they were stealing things. Here’s a picture
						of them,” right. And I’m like, “What is that, and how are people on
						team not stopping it?” And not to pick on Nextdoor because there
						are… You know Google is like, they can’t distinguish pieces from
						different races. You look at all of these different groups. You look
						at Airbnb, their platform has ways to… They’re now struggling with
						trying to correct this element of racism on their platform. It’s
						because you don’t have people who look like your user base involved
						in the products. You’re not making the best decisions and you’re not
						surfacing the problems early enough, right, because you don’t have
						any friends from that demographic, because you don’t have anybody in
						that circle because all of your friends are in tech and they all
						look the same. So, there’s like the… You get better products, you
						make better decisions, you have better financial results but also,
						it’s the right thing to do in this environment. So, hopefully,
						things will change but it’s not like this incremental change. It
						really is about changing the whole way the system works and really
						thinking about how do we bring more people in who have been excluded
						and how do we change how people get brought in and how people are
						given opportunities?
					</p>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default RandPaul;
